import { SyllographJSON } from "../shared/jsontypes";
import { Vector } from "vector2d/src/Vector";
import { Argument } from "./argument";
import { ArgumentRefutation } from "./argumentrefutation";
import { CanvasObject } from "./canvaselement";
import { Premise } from "./premise";
import { FindArgumentInParents, FindPremiseInParents, GetAllArgumentDescendants, GetAllArgumentParents, GetMousePos, GetMousePosOnCanvas } from "./utilities";
import { Wire } from "./wire";
import { Syllograph } from "./syllograph";
import { Socket } from "socket.io-client";



export class RefutationManager
{


	public OnAddedArgumentRefutation: Function = new Function();
	public OnRemovedArgumentRefutation: Function = new Function();
	public Syllograph: Syllograph;

	get Refutations(): ArgumentRefutation[]
	{
		return this._argRefutations;
	}

	private _argRefutations: ArgumentRefutation[] = [];

	private _wireBeingDragged: Wire | null = null;
	private _argumentToConnectWireTo: Argument | null = null;
	private _premiseToConnectWireTo: Premise | null = null;
	private _premiseWireDragsFrom: Premise | null = null;
	private _argumentWireDragsFrom: Argument | null = null;

	private _canvas: HTMLCanvasElement;

	constructor(canvas: HTMLCanvasElement, syllograph: Syllograph)
	{
		this._canvas = canvas;
		this.Syllograph = syllograph;
	}


	public Draw(context: CanvasRenderingContext2D)
	{
		for (let i = 0; i < this._argRefutations.length; i++)
		{
			const element = this._argRefutations[i];
			element.Draw(context);
		}
	}

	public OnPremiseWireButtonClicked(arg: Argument, premise: Premise, canvasObjects: CanvasObject[])
	{
		// Is there an existing wire?
		let existingRefutation = this._argRefutations.find((x) => x.Refuted == premise);

		if (existingRefutation)
		{
			this.RemoveRefutation(existingRefutation, canvasObjects);

			this.DragWireFromConclusion(existingRefutation.Refuting, existingRefutation.Refuted, canvasObjects);
		}
		else
		{
			this.DragWireFromPremise(premise, null, canvasObjects);
		}
	}

	public OnConclusionWireButtonClicked(arg: Argument, canvasObjects: CanvasObject[])
	{
		console.log("OnConclusionWireButtonClicked");

		if (arg.IsRoot)
		{
			return;
		}

		let existingRefutation = this._argRefutations.find((x) => x.Refuting == arg);

		if (existingRefutation)
		{
			this.RemoveRefutation(existingRefutation, canvasObjects);
			this.DragWireFromPremise(existingRefutation.Refuted, existingRefutation.Refuting, canvasObjects);
		}
		else
		{
			this.DragWireFromConclusion(arg, null, canvasObjects);
		}
	}

	private DragWireFromPremise(premise: Premise, argument: Argument | null, canvasObjects: CanvasObject[])
	{
		let wire = this.AddWire(premise.WirePosition);//add(this.Syllograph.Camera.Position));

		if (argument)
		{
			wire.MoveEnd(argument.ConclusionWirePosition);//.);//add(this.Syllograph.Camera.Position));
		}

		console.log("DragWireFromPremise");

		this._wireBeingDragged = wire;
		this._premiseWireDragsFrom = premise;

		canvasObjects.push(wire);
	}

	private DragWireFromConclusion(argument: Argument, premise: Premise | null, canvasObjects: CanvasObject[])
	{
		let wire = this.AddWire(argument.ConclusionWirePosition);//.);//add(this.Syllograph.Camera.Position));

		if (premise)
		{
			wire.MoveEnd(premise.WirePosition);//add(this.Syllograph.Camera.Position));
		}

		this._wireBeingDragged = wire;
		this._argumentWireDragsFrom = argument;

		canvasObjects.push(wire);
	}

	private AddWire(pos: Vector)
	{
		return new Wire(pos, 15, 1, this._canvas);
	}

	public OnMouseDrag(canvasObjects: CanvasObject[], ev: MouseEvent | TouchEvent)
	{
		if (this._wireBeingDragged != null)
		{
			let wire = this._wireBeingDragged;
			let realPos = GetMousePosOnCanvas(this._canvas, ev);

			let target = ev.target;


			if (realPos != undefined)
			{
				wire.MoveEnd(new Vector(realPos.x, realPos.y));//add(this.Syllograph.Camera.Position));

				if (window.TouchEvent && ev instanceof TouchEvent)
				{
					target = document.elementFromPoint(ev.touches[0].clientX, ev.touches[0].clientY);
				}
			}




			if (target instanceof HTMLElement)
			{
				if (this._premiseWireDragsFrom)
				{
					let parentArg = FindArgumentInParents(target, canvasObjects);

					if (parentArg != null &&
						parentArg != this._premiseWireDragsFrom?.Argument &&
						parentArg.IsRoot == false &&
						GetAllArgumentParents(this._premiseWireDragsFrom.Argument, this._argRefutations).includes(parentArg) == false)
					{
						let existingRefutation = this._argRefutations.find((x) => x.Refuting == parentArg);

						if (existingRefutation == null)
						{
							this._argumentToConnectWireTo = parentArg;
							wire.MoveEnd(parentArg.ConclusionWirePosition);//.);//add(this.Syllograph.Camera.Position));
						}
					}
					else
					{
						this._argumentToConnectWireTo = null;
					}
				}
				else if (this._argumentWireDragsFrom)
				{
					let parentPremise = FindPremiseInParents(target, canvasObjects);

					if (parentPremise != null &&
						parentPremise.Argument != this._argumentWireDragsFrom &&
						GetAllArgumentDescendants(this._argumentWireDragsFrom, this._argRefutations).includes(parentPremise.Argument) == false)
					{
						this._premiseToConnectWireTo = parentPremise;
						wire.MoveEnd(parentPremise.WirePosition);//add(this.Syllograph.Camera.Position));
					}
					else
					{
						this._premiseToConnectWireTo = null;
					}

				}
			}
		}
	}


	public OnMouseUp(canvasObjects: CanvasObject[], socket: Socket | null = null)
	{
		if (this._argumentToConnectWireTo &&
			this._wireBeingDragged &&
			this._premiseWireDragsFrom)
		{
			if (socket)
			{
				let graphID = this.Syllograph.ID;
				let premiseID = this._premiseWireDragsFrom.NetworkID;
				let argID = this._argumentToConnectWireTo.NetworkID;

				socket.emit("add-refutation", graphID, premiseID, argID);
			}
			else
			{
				this.AddArgumentRefutation(this._premiseWireDragsFrom, this._argumentToConnectWireTo, canvasObjects, this._canvas);
			}
		}
		else if (this._wireBeingDragged &&
			this._argumentWireDragsFrom &&
			this._premiseToConnectWireTo)
		{
			if (socket)
			{
				let graphID = this.Syllograph.ID;
				let premiseID = this._premiseToConnectWireTo.NetworkID;
				let argID = this._argumentWireDragsFrom.NetworkID;

				socket.emit("add-refutation", graphID, premiseID, argID);
			}
			else
			{
				this.AddArgumentRefutation(this._premiseToConnectWireTo, this._argumentWireDragsFrom, canvasObjects, this._canvas);
			}
		}

		this.RemoveDraggedWire(canvasObjects);


		this._argumentToConnectWireTo = null;
		this._premiseWireDragsFrom = null;
		this._argumentWireDragsFrom = null;
		this._wireBeingDragged = null;
		this._premiseToConnectWireTo = null;
	}

	private RemoveDraggedWire(canvasObjects: CanvasObject[])
	{
		if (this._wireBeingDragged)
		{
			let index = canvasObjects.indexOf(this._wireBeingDragged);
			canvasObjects.splice(index, 1);
		}
	}

	public RemoveRefutation(refutation: ArgumentRefutation, canvasObjects: CanvasObject[], emit = true)
	{
		if (this.Syllograph.Socket && emit)
		{
			this.Syllograph.Socket.emit("remove-refutation", this.Syllograph.ID, refutation.NetworkID);
		}
		else
		{

			let refutationID = canvasObjects.indexOf(refutation.RefutationWire);
			canvasObjects.splice(refutationID, 1);

			let otherID = this._argRefutations.indexOf(refutation);
			this._argRefutations.splice(otherID, 1);

			this.OnRemovedArgumentRefutation(refutation);

		}
	}

	public AddArgumentRefutation(refuted: Premise, refuting: Argument, canvasObjects: CanvasObject[], canvas: HTMLCanvasElement, networkID = "none")
	{
		var newRefutation = new ArgumentRefutation(refuted, refuting, canvas);
		newRefutation.NetworkID = networkID;
		this._argRefutations.push(newRefutation);

		canvasObjects.push(newRefutation.RefutationWire);

		this.OnAddedArgumentRefutation(newRefutation);

		return newRefutation;
	}

	public UpdateFromJSONGraph(graph: SyllographJSON, syllograph: Syllograph)
	{
		// console.log("RefutationManager: UpdateFromJSONGraph");
		graph.Refutations.forEach((x) =>
		{
			let refutation = this.Refutations.find((y) => y.NetworkID == x.ID);

			if (refutation == null)
			{
				// Missing a refutation, let's add one.

				// Find matching argument:
				let arg = syllograph.GetArgumentWithID(x.ArgumentID);
				let premise = syllograph.GetPremiseWithID(x.PremiseID);

				if (arg && premise)
				{
					this.AddArgumentRefutation(premise, arg, syllograph.CanvasObjects, this._canvas, x.ID);
				}
				else
				{
					if (!arg) console.log("Couldn't find arg with ID: " + x.ArgumentID);
					if (!premise) console.log("Couldn't find premise with ID: " + x.PremiseID);
				}
			}



		});

		for (let i = this.Refutations.length - 1; i >= 0; i--)
		{
			let localRefutation = this.Refutations[i];

			// Does x exist on server?
			let exists = false;

			graph.Refutations.forEach((serverRefutation) =>
			{
				if (localRefutation.NetworkID == serverRefutation.ID)
				{
					exists = true;
				}
			});

			if (exists == false)
			{
				this.RemoveRefutation(localRefutation, syllograph.CanvasObjects, false);
			}
		}
	}
}