import { Vector } from "vector2d/src/Vector";
import { CanvasObject } from "./canvaselement";
import { GraphManager } from "./graphmanager";


export class WireElement extends CanvasObject
{
	public Velocity: Vector;

	public NextElem!: WireElement;
	public PrevElem!: WireElement;

	public ChainDistance: number;

	public RenderOffset = new Vector(0,0);


	constructor(pos: Vector, chainDistance: number, canvas: HTMLCanvasElement)
	{
		super(pos, canvas);
		
		this.Velocity = new Vector(0, 0);
		this.ChainDistance = chainDistance;
	}

	public Draw(context: CanvasRenderingContext2D)
	{
		// context.fillStyle = "black";
		// context.fillRect(this.Position.x, this.Position.y, 10, 10);
	}

	public Update()
	{
		if (this.PrevElem != null &&
			this.NextElem != null)
		{
			let gravity = new Vector(0, 0.1);
			this.Velocity.add(gravity);


			if (this.PrevElem != null)
			{
				let prevPos = new Vector(this.PrevElem.Position.x, this.PrevElem.Position.y);
				let toPrev = prevPos.subtract(this.Position);
				let distFromPrev = toPrev.magnitude();

				if (distFromPrev > this.ChainDistance)
				{
					let vel = toPrev.multiplyByScalar(0.05);
					this.Velocity.add(vel);
				}
			}

			if (this.NextElem != null)
			{
				let nextPos = new Vector(this.NextElem.Position.x, this.NextElem.Position.y);
				let toNext = nextPos.subtract(this.Position);
				let distFromNext = toNext.magnitude();

				if (distFromNext > this.ChainDistance)
				{
					let vel = toNext.multiplyByScalar(0.05);
					this.Velocity.add(vel);
				}
			}
		}

		
		this.Velocity.multiplyByScalar(0.93);

		
		this.Position.add(this.Velocity);
	}
}

export class Wire extends CanvasObject
{
	public WireElements: WireElement[] = [];

	public Iterations = 2;
	public ChainDistance: number;


	get PositionDirect(): Vector
	{
		let pos = new Vector(this.Position.x, this.Position.y);

		return pos.add(this.RenderOffset);
	}
	public set PositionDirect(value: Vector)
	{
		let pos = new Vector(this.Position.x, this.Position.y);

		this.RenderOffset = value.subtract(pos);
	}

	public RenderOffset = new Vector(0,0);


	constructor(pos: Vector, numElements: number, chainDistance: number, canvas: HTMLCanvasElement)
	{
		super(pos, canvas);
		
		this.ChainDistance = chainDistance;

		let initalPos = this.Position;
		let prevElem!: WireElement;
		
		for (let i = 0; i < numElements; i++)
		{
			const element = new WireElement(initalPos, this.ChainDistance, canvas);
			// initalPos.x += 20;


			if (prevElem != null)
			{
				element.PrevElem = prevElem;
				prevElem.NextElem = element;
			}

			prevElem = element;

			this.WireElements.push(element);
		}
	}

	public MoveEnd(newPos: Vector)
	{
		if (this.WireElements.length < 2)
		{
			return;
		}

		let lastElem = this.WireElements[this.WireElements.length - 1];
		lastElem.Position = new Vector(newPos.x, newPos.y);

		// lastElem.Position.add(this.RenderOffset);
		// let pos = new Vector(this.Position.x, this.Position.y);
		// pos.add(this.RenderOffset);
		
	}

	public Draw(context: CanvasRenderingContext2D)
	{
		if (this.WireElements.length == 0)
		{
			return;
		}

		let offset = new Vector(0,0);// this.RenderOffset;

		for (let i = 0; i < this.WireElements.length - 1; i++)
		{
			const element = this.WireElements[i];
			const nextElement = this.WireElements[i + 1];

			element.Draw(context);

			context.strokeStyle = "white";
			context.beginPath();
			context.lineWidth = 5;
			context.lineCap = "round";
			context.moveTo(element.Position.x + offset.x, element.Position.y + offset.y);
			context.lineTo(nextElement.Position.x + offset.x, nextElement.Position.y + offset.y);
			context.stroke();
		}
		
		

		this.WireElements[this.WireElements.length - 1].Draw(context);
	}
	
	public HandleOffsetByGraph(delta: Vector): void
	{
		this.AddDeltaToAllElements(delta.multiplyByScalar(-1));
	}
	
	public AddDeltaToAllElements(delta: Vector)
	{
		for (let i = 0; i < this.WireElements.length; i++)
		{
			const element = this.WireElements[i];
			element.Position.add(delta);
		}
	}

	public Update()
	{
		if (this.WireElements.length == 0)
		{
			return;
		}

		let pos = new Vector(this.Position.x, this.Position.y);
		// pos.add(this.RenderOffset);
		
		this.WireElements[0].Position = pos;
		
		for (let i = 0; i < this.Iterations; i++)
		{
			for (let j = 0; j < this.WireElements.length; j++)
			{
				const element = this.WireElements[j];
				element.Update();
			}
		}

		// for (let i = 0; i < this.WireElements.length; i++)
		// {
		// 	const element = this.WireElements[i];
			
		// 	element.Position.add(this.RenderOffset);
		// }

	}
}