import { Vector } from "vector2d";
import { GraphManager } from "./graphmanager";


export class ContextMenuManager
{
	public static Instance: ContextMenuManager;
	public MenuDiv: HTMLDivElement | null = null;
	
	public Buttons: { Text: string, Func: Function, Disabled: boolean }[] = [];
	
	constructor()
	{
		ContextMenuManager.Instance = this;
		
		document.addEventListener('mousedown', (ev) =>
		{
			if (ev.target != this.MenuDiv &&
				(ev.target as HTMLElement).className != "contextmenuButton")
			{
				this.RemoveContextMenu();
			}
		});
	}
	
	public OpenContextMenu(position: Vector)
	{
		this.RemoveContextMenu();
		
		if (this.Buttons.length == 0)
		{
			return;
		}
		
		let parentDiv = document.getElementById("canvasdiv") as HTMLDivElement;
		let menuDiv = document.createElement("div");
		menuDiv.className = "contextmenu";

		parentDiv.appendChild(menuDiv);

		menuDiv.style.position = "absolute";
		menuDiv.style.left = position.x + "px";
		menuDiv.style.top = position.y + "px";

		if (GraphManager.Instance.ActiveSyllograph)
		{
			let zIndex = ++GraphManager.Instance.ActiveSyllograph.CurrentZIndex;
			menuDiv.style.zIndex = zIndex.toString();
		}
		
		for (let i = 0; i < this.Buttons.length; i++)
		{
			const element = this.Buttons[i];
			
			let button = document.createElement('button');
			button.className = "contextmenuButton";
			button.textContent = element.Text;
			button.disabled = element.Disabled;
			button.onclick = (ev) =>
			{
				element.Func();
				this.RemoveContextMenu();
			}
			
			menuDiv.appendChild(button);
		}
		
		this.Buttons = [];
		
		this.MenuDiv = menuDiv;
	}
	
	public RemoveContextMenu()
	{
		if (this.MenuDiv)
		{
			this.MenuDiv.remove();
		}
	}
	
	public AddButton(text: string, func: Function, disabled = false)
	{
		this.Buttons?.push({ Text: text, Func: func, Disabled: disabled });
	}
}