import { SyllographJSON } from "../shared/jsontypes";
import { Vector } from "vector2d/src/Vector";
import { Argument } from "./argument";
import { ArgumentRefutation } from "./argumentrefutation";
import { CanvasObject } from "./canvaselement";
import { CanvasGrid } from "./canvasgrid";
import { CanvasNode } from "./canvasnode";
import { ContextMenuManager } from "./contextmenumanager";
import { Parallel } from "./parallel";
import { Premise } from "./premise";
import { RefutationManager } from "./refutationmanager";
import { SuccessManager } from "./successmanager";
import { Camera } from "./camera";
import { Socket } from "socket.io-client";
import { JSONToVector, VectorToJSON } from "../shared/sharedutils";
import { GraphManager } from "./graphmanager";
import { GetMousePosOnCanvas } from "./utilities";


export class Syllograph
{


	get Dragging(): boolean
	{
		return this._clickedCanvas;
	}

	get CameraPosition(): Vector
	{
		let pos = new Vector(this.Camera.Position.x, this.Camera.Position.y);
		return pos.add(new Vector(this._canvas.width / 2, this._canvas.height / 2));
	}

	public OnPremiseSubgraphClicked = new Function();
	public OnPremiseSubgraphRemoveClicked = new Function();
	public OnSuccessManagerUpdated = new Function();
	public OnPremiseRemove = new Function();
	public OnRemovedArgument = new Function();
	public ID: string = "";
	public Camera: Camera;
	public Socket: Socket | null;

	get CanvasObjects(): CanvasObject[]
	{
		return this._canvasObjects;
	}

	public CurrentZIndex = 0;

	private _canvas: HTMLCanvasElement;
	private _refutationManager: RefutationManager;
	private _successManager: SuccessManager;

	private _canvasObjects: CanvasObject[] = [];

	private _clickedCanvas = false;
	private _canvasGrid: CanvasGrid;

	private _prevTouchX: number = 0;
	private _prevTouchY: number = 0;


	constructor(canvas: HTMLCanvasElement, socket: Socket | null = null)
	{
		this._canvas = canvas;

		this._canvasGrid = new CanvasGrid(canvas, 15);
		this._canvasGrid.LineOpacity = 0.1;

		this.Camera = new Camera(this._canvasObjects, this._canvasGrid);
		this.Camera.Position.add(new Vector(-canvas.width / 2, -canvas.height / 2));


		this._refutationManager = new RefutationManager(canvas, this);

		this._refutationManager.OnAddedArgumentRefutation = (refutation: ArgumentRefutation) =>
		{
			this.UpdateSuccessFor(refutation.Refuted.Argument);
		}

		this._refutationManager.OnRemovedArgumentRefutation = (refutation: ArgumentRefutation) =>
		{
			this.UpdateSuccessFor(refutation.Refuted.Argument);
		}

		this._successManager = new SuccessManager();

		this._successManager.OnUpdated = () =>
		{
			this.OnSuccessManagerUpdated();
		};

		canvas.addEventListener('mousedown', (ev) => 
		{
			if (ev.button == 0 &&
				GraphManager.Instance.ActiveSyllograph == this)
			{
				this._clickedCanvas = true;
			}
		});

		document.addEventListener('mouseup', (ev) => 
		{
			if (ev.button == 0 &&
				GraphManager.Instance.ActiveSyllograph == this)
			{
				this._clickedCanvas = false;
				this._refutationManager.OnMouseUp(this._canvasObjects, this.Socket);
			}
		});

		document.addEventListener('mousemove', (ev) =>
		{
			if (this._clickedCanvas)
			{
				let delta = new Vector(ev.movementX, ev.movementY);
				this.Camera.Move(delta.multiplyByScalar(-1));
			}

			this._refutationManager.OnMouseDrag(this._canvasObjects, ev);
		});

		canvas.addEventListener('touchstart', (ev) =>
		{
			if (ev.touches.length == 1 &&
				GraphManager.Instance.ActiveSyllograph == this)
			{
				this._clickedCanvas = true;

				this._prevTouchX = ev.touches[0].clientX;
				this._prevTouchY = ev.touches[0].clientY;
			}
		});
		document.addEventListener('touchmove', (ev) =>
		{
			if (this._clickedCanvas &&
				ev.touches.length == 1)
			{

				let delta = new Vector(ev.touches[0].clientX - this._prevTouchX, ev.touches[0].clientY - this._prevTouchY);
				this._prevTouchX = ev.touches[0].clientX;
				this._prevTouchY = ev.touches[0].clientY;
				this.Camera.Move(delta.multiplyByScalar(-1));
			}
			this._refutationManager.OnMouseDrag(this._canvasObjects, ev);
		});
		document.addEventListener('touchend', (ev) =>
		{
			if (GraphManager.Instance.ActiveSyllograph == this)
			{
				this._clickedCanvas = false;
				this._refutationManager.OnMouseUp(this._canvasObjects, this.Socket);
			}
		});

		this.Socket = socket;

		// canvas.removeEventListener('contextmenu', this.OnContextMenu);
		// canvas.addEventListener('contextmenu', (ev) => this.OnContextMenu(ev));
	}

	public OnContextMenu(ev: MouseEvent)
	{
		ContextMenuManager.Instance.AddButton("Add argument", () =>
		{
			let realPos = GetMousePosOnCanvas(this._canvas, ev);

			if (realPos != undefined)
			{
				let pos = new Vector(realPos.x, realPos.y).add(this.Camera.Position);
				if (this.Socket)
				{
					this.Server_AddNewArgument(this.Socket, pos);
				}
				else
				{
					let newArg = this.AddNewArgument();
					newArg.Position = pos;
				}
			}
		})

		ContextMenuManager.Instance.AddButton("Add parallel", () =>
		{
			let realPos = GetMousePosOnCanvas(this._canvas, ev);

			if (realPos != undefined)
			{
				let pos = new Vector(realPos.x, realPos.y).add(this.Camera.Position);
				let newParallel = this.AddNewParallel(true, pos);
			}
			// if (newParallel)
			// {
			// 	newParallel.Position = pos;
			// }
		});
	}





	public UpdateSuccessFor(argument: Argument)
	{
		this._successManager.ProcessArgument(argument, this._refutationManager.Refutations);
	}


	public Draw(context: CanvasRenderingContext2D)
	{
		this.Camera.Update(context);
		this._refutationManager.Draw(context);

		for (let i = 0; i < this._canvasObjects.length; i++)
		{
			const element = this._canvasObjects[i];

			element.Update();
			element.Draw(context);
		}
	}

	public Server_AddNewArgument(socket: Socket, position: Vector | null = null)
	{
		let graphID = this.ID;

		let pos = VectorToJSON(this.CameraPosition);

		if (position != null)
		{
			pos = VectorToJSON(position);
		}

		socket.emit("add-argument", graphID, pos);
	}

	public Server_AddNewArgumentAsRefutation(socket: Socket, premise: Premise, position: Vector)
	{
		let graphID = this.ID;

		let pos = VectorToJSON(this.CameraPosition);

		if (position != null)
		{
			pos = VectorToJSON(position);
		}

		socket.emit("add-argument-as-refutation", graphID, premise.NetworkID, pos);
	}

	public AddNewArgument()
	{
		let canvas = this._canvas;
		let cameraPos = this.Camera.Position;

		let arg = new Argument(cameraPos, canvas, () =>
		{
			this.OnArgumentRefreshed(arg);
		});

		arg.Socket = this.Socket;


		arg.OnDeleteClicked = (networkAction: boolean) =>
		{
			this.RemoveArgument(arg, networkAction);
		};

		arg.OnPremiseRefuteClicked = (premise: Premise) =>
		{
			let argPos = arg.Position;
			let refutations = this._refutationManager.Refutations.filter((x) => x.Refuted.Argument == arg);
			let yOffset = -50;

			for (let i = 0; i < refutations.length; i++)
			{
				const element = refutations[i];
				yOffset += element.Refuting.Height + 10;
			}

			let newArgPos = new Vector(argPos.x, argPos.y).add(new Vector(-arg.Width - 100, yOffset));

			if (this.Socket)
			{
				this.Server_AddNewArgumentAsRefutation(this.Socket, premise, newArgPos);
			}
			else
			{
				let newArg = this.AddNewArgument();
				newArg.Position = newArgPos;

				this.AddArgumentRefutation(premise, newArg);
			}

		}

		arg.OnPremiseRemove = (premise: Premise, networkAction: boolean) =>
		{
			let refutationToRemove = this._refutationManager.Refutations.find((x) => x.Refuted == premise);

			if (refutationToRemove != null)
			{
				this.RemoveRefutation(refutationToRemove, networkAction);
			}

			this.OnPremiseRemove(premise, networkAction);
		}

		arg.OnPremiseWireButtonClicked = (arg: Argument, premise: Premise) =>
		{
			this._refutationManager.OnPremiseWireButtonClicked(arg, premise, this._canvasObjects);
		}

		arg.OnConclusionWireButtonClicked = (arg: Argument) =>
		{
			this._refutationManager.OnConclusionWireButtonClicked(arg, this._canvasObjects);
		}

		arg.OnPremiseSubgraphClicked = (premise: Premise) =>
		{
			this.OnPremiseSubgraphClicked(arg, premise);
		}
		arg.OnPremiseSubgraphRemoveClicked = (premise: Premise) =>
		{
			this.OnPremiseSubgraphRemoveClicked(arg, premise);
		}

		this.RegisterCanvasObject(arg);

		return arg;
	}

	public AddNewParallel(networkAction = true, position = new Vector(0, 0))
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("add-parallel", this.ID, VectorToJSON(position));

			return null;
		}

		let canvas = this._canvas;
		let parallel = new Parallel(position, canvas);

		parallel.Socket = this.Socket;


		parallel.OnDeleteClicked = (networkAction: boolean) =>
		{
			this.RemoveParallel(parallel, networkAction);
		}

		this.RegisterCanvasObject(parallel);

		return parallel;
	}

	public AddNewCanvasNode()
	{
		let node = new CanvasNode(new Vector(0, 0), this._canvas);

		this.RegisterCanvasObject(node);

		return node;
	}

	public RemoveParallel(parallel: Parallel, networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("remove-node", parallel.NetworkID);
			return;
		}

		let id = this._canvasObjects.indexOf(parallel);
		this._canvasObjects.splice(id, 1);
	}

	public RegisterCanvasObject(obj: CanvasObject)
	{
		if (obj instanceof CanvasNode)
		{
			let canvasNode = obj as CanvasNode;

			canvasNode.OnMouseDown = () =>
			{
				this.CurrentZIndex++;
				canvasNode.ZIndex = this.CurrentZIndex;
			}

			this.CurrentZIndex++;
			canvasNode.ZIndex = this.CurrentZIndex;
		}

		this._canvasObjects.push(obj);
	}

	public GetAllArguments(): Argument[]
	{
		let args = this._canvasObjects.filter((x) => x instanceof Argument);
		let argArray: Argument[] = [];

		for (let i = 0; i < args.length; i++)
		{
			argArray.push(args[i] as Argument);
		}

		return argArray;
	}

	public GetAllParallels(): Parallel[]
	{
		let parallels = this._canvasObjects.filter((x) => x instanceof Parallel);
		let parallelArray: Parallel[] = [];

		for (let i = 0; i < parallels.length; i++)
		{
			parallelArray.push(parallels[i] as Parallel);
		}

		return parallelArray;
	}

	private OnArgumentRefreshed(arg: Argument)
	{
		this.UpdateSuccessFor(arg);
	}

	public RemoveArgument(arg: Argument, networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("remove-node", arg.NetworkID);
			return;
		}

		let id = this._canvasObjects.indexOf(arg);
		this._canvasObjects.splice(id, 1);

		arg.Premises.forEach(premise =>
		{
			let refutationToRemove = this._refutationManager.Refutations.find((x) => premise == x.Refuted);

			if (refutationToRemove != null)
			{
				this.RemoveRefutation(refutationToRemove, networkAction);
			}
		});

		let refutationToRemove2 = this._refutationManager.Refutations.find((x) => x.Refuting == arg);

		if (refutationToRemove2 != null)
		{
			this.RemoveRefutation(refutationToRemove2, networkAction);
			this.UpdateSuccessFor(refutationToRemove2.Refuted.Argument);
		}

		this.OnRemovedArgument(arg);
	}

	public RemoveAllArguments(networkAction = true)
	{
		let allArgs = this.GetAllArguments();
		allArgs.forEach((x) =>
		{
			this.RemoveArgument(x, networkAction);
		});
	}

	public GetRootArgument(): Argument | null
	{
		let args = this._canvasObjects.filter((x) => x instanceof Argument);
		let rootArg = args.find((x) => (x as Argument).IsRoot);

		if (rootArg)
		{
			return rootArg as Argument;
		}

		return null;
	}

	public RemoveRefutation(refutation: ArgumentRefutation, networkAction = true)
	{
		// console.trace("Syllograph: Remove refutation");
		this._refutationManager.RemoveRefutation(refutation, this._canvasObjects, networkAction);
	}

	public AddArgumentRefutation(refuted: Premise, refuting: Argument)
	{
		this._refutationManager.AddArgumentRefutation(refuted, refuting, this._canvasObjects, this._canvas);
	}


	public ShowAllCanvasNodes()
	{
		this._canvasObjects.forEach((x) => (x).Show());
	}

	public HideAllCanvasNodes()
	{
		this._canvasObjects.forEach((x) => (x).Hide());
	}

	public SetFromJSON(graph: SyllographJSON)
	{
		this.ID = graph.ID;

		let allArgs = this.GetAllArguments();
		allArgs.forEach((x) =>
		{
			let id = x.NetworkID;
			let matchingArgJSON = graph.Arguments.find((y) => y.ID == id);

			if (matchingArgJSON)
			{
				// Update the existing argument:
				x.CopyFromJSONArgument(matchingArgJSON);
			}
			else
			{
				// Remove this argument since it's not in the server:
				x.DeleteNode(false);
			}
		});

		// Now go through and check for new arguments:
		graph.Arguments.forEach((x) =>
		{
			let matchingArg = this.GetArgumentWithID(x.ID);

			if (matchingArg == null)
			{
				let newArg = this.AddNewArgument();
				newArg.CopyFromJSONArgument(x);

				if (GraphManager.Instance.ActiveSyllograph != this)
				{
					newArg.Hide();
				}
			}
		});


		this._refutationManager.UpdateFromJSONGraph(graph, this);


		let allParallels = this.GetAllParallels();
		allParallels.forEach((x) =>
		{
			let id = x.NetworkID;
			let matchingParallelJSON = graph.Parallels.find((y) => y.ID == id);

			if (matchingParallelJSON)
			{
				// Update the existing parallel:
				x.SetFromJSON(matchingParallelJSON);
			}
			else
			{
				// Remove this parallel since it's not in the server:
				x.DeleteNode(false);
			}
		});

		graph.Parallels.forEach((x) =>
		{
			let matchingParallel = this.GetParallelWithID(x.ID);

			if (matchingParallel == null)
			{
				let newParallel = this.AddNewParallel(false);

				if (newParallel)
				{
					newParallel.SetFromJSON(x);

					if (GraphManager.Instance.ActiveSyllograph != this)
					{
						newParallel.Hide();
					}
				}
			}
		});
	}

	public GetArgumentWithID(id: string): Argument | undefined
	{
		return this.GetAllArguments().find((x) => x.NetworkID == id);
	}

	public GetParallelWithID(id: string): Parallel | undefined
	{
		return this.GetAllParallels().find((x) => x.NetworkID == id);
	}

	public GetPremiseWithID(id: string): Premise | null
	{
		let allArgs = this.GetAllArguments();
		for (let i = 0; i < allArgs.length; i++)
		{
			let arg = allArgs[i];

			for (let j = 0; j < arg.Premises.length; j++)
			{
				let premise = arg.Premises[j];

				if (premise.NetworkID == id)
				{
					return premise;
				}
			}
		}

		return null;
	}

}