
export class PopupButton
{
	public Button: HTMLButtonElement;
	
	constructor(text: string, onclicked: Function)
	{
		let button = document.createElement('button');
		button.className = "syllobutton popupbutton";
		button.textContent = text;
		button.style.bottom = "0";
		
		button.onclick = (ev) => onclicked();
		
		this.Button = button;
	}
}

export class PopupManager
{
	public static Instance: PopupManager;
	
	public OnOK = new Function();
	public OnCancel = new Function();
	
	get InputResult(): string | undefined
	{
		return this._inputField?.value;
	}
	
	private _popup: HTMLDivElement | null = null;
	private _inputField: HTMLInputElement | null = null;
	
	constructor()
	{
		PopupManager.Instance = this;
	}
	
	
	
	
	public AddPopup(message: string, ok: boolean = true, cancel: boolean = false, input: string = "")
	{
		let parentDiv = document.getElementById('popupdiv');
		let canvas = document.getElementById('canvas') as HTMLCanvasElement;
		
		if (parentDiv == null)
		{
			return;
		}
		
		parentDiv.style.backgroundColor = "rgba(0,0,0,0.85)";
		
		let popupDiv = document.createElement('div');
		popupDiv.className = "popup";
		popupDiv.style.left = canvas.width / 2 + "px";
		popupDiv.style.top = canvas.height / 2 + "px";
		
		let topBar = document.createElement('div');
		topBar.className = "topbar";
		topBar.style.height = "20px";
		popupDiv.appendChild(topBar);
			
		let messageDiv = document.createElement('div');
		messageDiv.className = "message";
		messageDiv.innerText = message;
		popupDiv.appendChild(messageDiv);
		
		
		if (input.length > 0)
		{
			let inputField = document.createElement('input');
			inputField.className = "nodetextarea";
			inputField.value = input;
			
			inputField.style.display = "block";
			popupDiv.appendChild(inputField);
			
			this._inputField = inputField;
		}
		
		if (ok)
		{
			popupDiv.appendChild(new PopupButton("OK", () =>
			{
				this.OnOK();
				this.ClosePopup();
			}).Button);
		}
		
		if (cancel)
		{
			popupDiv.appendChild(new PopupButton("Cancel", () =>
			{
				this.OnCancel();
				this.ClosePopup();
			}).Button);
		}
		
		
		parentDiv.appendChild(popupDiv);
		parentDiv.style.pointerEvents = "inherit";
		
		if (this._inputField)
		{
			this._inputField.focus();
			this._inputField.select();
		}
		
		this._popup = popupDiv;
	}
	
	public ClosePopup()
	{
		if (this._popup == null)
		{
			return;
		}
		
		let parentDiv = document.getElementById('popupdiv');
		
		if (parentDiv)
		{
			parentDiv.style.pointerEvents = "none";
			parentDiv.style.background = "none";
		}
		
		this._popup.remove();

	}
}