import { ArgumentJSON, PremiseJSON, TextFieldJSON } from "../shared/jsontypes";
import { Vector } from "vector2d";
import { CanvasNode } from "./canvasnode";
import { GREEN_COLOR, RED_COLOR } from "./constants";
import { ContextMenuManager } from "./contextmenumanager";
import { PopupManager } from "./popupmanager";
import { Premise } from "./premise";
import
{
	GetElementPos,
	GetMousePosOnCanvas,
} from "./utilities";
import { JSONToVector } from "../shared/sharedutils";
import { GraphManager } from "./graphmanager";
import { TextField } from "./textfield";
import { Socket } from "socket.io-client";

class TempClass
{
	public Weight: number;
	public Premise: Premise;

	constructor(weight: number, premise: Premise)
	{
		this.Weight = weight;
		this.Premise = premise;
	}
}

export class Argument extends CanvasNode
{
	public OnPremiseWireButtonClicked = new Function();
	public OnConclusionWireButtonClicked = new Function();
	public OnPremiseRefuteClicked = new Function();
	public OnPremiseRemove = new Function();
	public OnPremiseSubgraphClicked = new Function();
	public OnPremiseSubgraphRemoveClicked = new Function();
	public OnConclusionTextChanged = new Function();

	public UserCapturingConclusion = "";

	get Socket(): Socket | null
	{
		return super.Socket;
	}
	set Socket(value: Socket | null)
	{
		super.Socket = value;
		this._conclusionTextField.Socket = value;
	}

	// private _conclusionInputField: HTMLTextAreaElement;
	private _conclusionTextField: TextField;
	private _fancyPlusSign: HTMLDivElement;
	private _addPremiseButton: HTMLButtonElement;
	private _lastConclusionText: string = "";
	private _lastConclusionTextNetworkUpdate = new Date().getTime();

	get MarkedSuccessful(): boolean
	{
		return this._markedSuccessful;
	}

	get Conclusion(): string
	{
		return this._conclusionTextField.Field.value;
	}
	set Conclusion(value: string)
	{
		this._conclusionTextField.Field.value = value;
	}

	get Premises(): Premise[]
	{
		return this._premises;
	}

	get ConclusionWirePosition(): Vector
	{
		var pos = GetElementPos(this._conclusionWireButton);

		return new Vector(pos.x, pos.y);
	}

	get IsRoot(): boolean
	{
		return this._isRoot;
	}
	set IsRoot(value: boolean)
	{
		this._isRoot = value;

		if (value)
		{
			this.EnableDeleteButton(false);
			this._conclusionWireButton.style.display = "none";
		}
	}

	private _premisesDiv: HTMLDivElement;
	private _successDiv: HTMLDivElement;

	private _premises: Premise[] = [];
	private _conclusionWireButton: HTMLButtonElement;

	private _isRoot = false;
	private _markedSuccessful = true;

	private _onRefreshed: Function;
	private _refresh = false;

	constructor(pos: Vector, canvas: HTMLCanvasElement, onRefreshed: Function)
	{
		super(pos, canvas);

		this.NodeTitle = "ARGUMENT";

		this._onRefreshed = onRefreshed;

		let nodeDiv = this._nodeDiv;

		this._premisesDiv = document.createElement("div");
		nodeDiv.appendChild(this._premisesDiv);

		let addPremiseButton = document.createElement("button");
		addPremiseButton.className = "addPremiseButton";
		addPremiseButton.tabIndex = 1;
		let fancyPlusSign = document.createElement("div");
		fancyPlusSign.className = "whitebox";
		fancyPlusSign.innerText = "+";
		this._fancyPlusSign = fancyPlusSign;
		addPremiseButton.appendChild(fancyPlusSign);
		this._addPremiseButton = addPremiseButton;

		nodeDiv.appendChild(addPremiseButton);
		addPremiseButton.onclick = (ev) => this.AddPremise("New premise");

		let successDiv = document.createElement("div");
		successDiv.className = "successDiv";
		nodeDiv.appendChild(successDiv);

		let conclusionDiv = document.createElement("div");
		conclusionDiv.className = "conclusionDiv";
		nodeDiv.appendChild(conclusionDiv);

		let conclusionDiv2 = document.createElement("div");
		conclusionDiv2.className = "conclusion";
		conclusionDiv2.innerText = "C";

		let conclusionInputField = new TextField(true, "Conclusion");
		this._conclusionTextField = conclusionInputField;
		// let conclusionInputField = document.createElement("textarea");
		// conclusionInputField.className = "nodetextarea";
		// HandleTextAreaResize(conclusionInputField);
		// conclusionInputField.textContent = "Conclusion";
		conclusionInputField.Field.addEventListener("input", (ev) =>
		{
			this.OnConclusionTextChanged(conclusionInputField.Field.value);
		});

		// conclusionInputField.addEventListener('focusin', (ev) =>
		// {
		// 	if (this.Socket)
		// 	{
		// 		this.Socket.emit("capture-conclusion-text", this.NetworkID);
		// 	}
		// });
		// conclusionInputField.addEventListener('focusout', (ev) =>
		// {
		// 	console.log("RELEASE");
		// 	if (this.Socket &&
		// 		this.UserCapturingConclusion == this.Socket.id)
		// 	{
		// 		this.Socket.emit("release-conclusion-text", this.NetworkID);
		// 	}
		// });

		conclusionDiv2.appendChild(conclusionInputField.Field);

		let conclusionWireButton = document.createElement("button");
		conclusionWireButton.className = "wirebutton";
		conclusionWireButton.style.marginLeft = "auto";
		conclusionWireButton.tabIndex = 1;
		conclusionDiv2.appendChild(conclusionWireButton);

		conclusionDiv.appendChild(conclusionDiv2);

		this._conclusionWireButton = conclusionWireButton;
		this._successDiv = successDiv;
		// this._conclusionInputField = conclusionInputField;

		nodeDiv.oncontextmenu = (ev) =>
		{
			if (
				ev.target == nodeDiv ||
				// ev.target == this.TopBarDiv ||
				ev.target == successDiv ||
				ev.target == conclusionDiv2
			)
			{
				ContextMenuManager.Instance.AddButton("Delete argument", () =>
				{
					this.DeleteNode();
				});
			}
		};

		conclusionWireButton.onmousedown = (ev) =>
			this.OnConclusionWireButtonClicked(this);


		conclusionWireButton.ontouchstart = (ev) =>
		{
			console.log("ontouchstart");
			this.OnConclusionWireButtonClicked(this);
		}

		this._lastConclusionText = this.Conclusion;

		this.AddPremise("Premise 1", false);
		this.AddPremise("Premise 2", false);
	}

	public AddPremise(text: string, networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("add-premise", this.NetworkID, text);
			return null;
		}

		let newPremise = new Premise(text, this);
		newPremise.Socket = this.Socket;

		newPremise.OnDeleteClicked = () =>
		{
			if (newPremise.HasSubGraph)
			{
				PopupManager.Instance.AddPopup(
					"Premise contains supporting argument. Delete?",
					true,
					true
				);
				PopupManager.Instance.OnOK = () => this.RemovePremise(newPremise);
			} else
			{
				this.RemovePremise(newPremise);
			}
		};

		newPremise.OnRefuteClicked = () =>
		{
			this.OnPremiseRefuteClicked(newPremise);
		};

		newPremise.OnWireButtonClicked = (premise: Premise) =>
		{
			this.OnPremiseWireButtonClicked(this, premise);
		};

		newPremise.OnPremiseDragOperationFinished = (ev: MouseEvent | TouchEvent) =>
		{
			let realPos = GetMousePosOnCanvas(this.Canvas, ev);

			if (realPos != undefined)
			{
				let mouseY = realPos.y;

				let newArray = this.PrepareReorderPremises();

				let newPremiseElem = newArray.find((x) => x.Premise == newPremise);

				if (newPremiseElem)
				{
					newPremiseElem.Weight = mouseY;
				}

				this.FinalizeReorderPremises(newArray);

				if (this.Socket)
				{
					let newIndex = this.Premises.indexOf(newPremise);
					this.Socket.emit("reorder-premise", newPremise.NetworkID, newIndex);
				}
			}
		};

		newPremise.OnSubgraphClicked = () =>
		{
			this.OnPremiseSubgraphClicked(newPremise);
		};
		newPremise.OnSubgraphRemoveClicked = () =>
		{
			this.OnPremiseSubgraphRemoveClicked(newPremise);
		};

		this._premises.push(newPremise);

		this._refresh = true;

		this._premisesDiv.appendChild(newPremise.Div);

		return newPremise;
	}

	private PrepareReorderPremises()
	{
		let children = this._premisesDiv.children;
		let newArray: TempClass[] = [];

		// for (let i = 0; i < this._premises.length; i++)
		for (let i = children.length - 1; i >= 0; i--)
		{
			const element = children[i];
			let premise = this._premises.find((x) => x.Div == element);

			if (premise)
			{
				let pos = GetElementPos(premise.Div);
				pos.y += premise.Div.clientHeight / 2;

				newArray.push(new TempClass(pos.y, premise));
			}

			this._premisesDiv.removeChild(element);
		}

		return newArray;
	}

	private FinalizeReorderPremises(newArray: TempClass[])
	{
		newArray.sort((a, b) => b.Weight - a.Weight);

		let newPremisesArray: Premise[] = [];

		for (let i = newArray.length - 1; i >= 0; i--)
		{
			const element = newArray[i];
			this._premisesDiv.appendChild(element.Premise.Div);
			newPremisesArray.push(element.Premise);
		}

		this._premises = newPremisesArray;

		this._refresh = true;
	}

	public ContainsPremise(premise: Premise)
	{
		return this._premises.includes(premise);
	}

	public RemovePremise(premise: Premise, networkAction = true)
	{
		if (networkAction && this.Socket)
		{
			this.Socket.emit("remove-premise", premise.NetworkID);
		} else
		{
			let index = this._premises.indexOf(premise);
			this._premises.splice(index, 1);
			premise.Div.remove();

			this.OnPremiseRemove(premise, networkAction);

			// this.RefreshNode();
			this._refresh = true;
		}
	}

	public CopyFromArgument(arg: Argument)
	{
		this.RemoveAllPremises();

		arg.Premises.forEach((element) =>
		{
			let newPremise = this.AddPremise(element.PremiseText);
		});

		this.RefreshNode();

		this.Conclusion = arg.Conclusion;
	}

	public CopyFromJSONArgument(arg: ArgumentJSON)
	{
		this.NetworkID = arg.ID;

		this.UserDraggingThisNode = arg.UserDraggingThisNode;

		if (
			this.UserDraggingThisNode &&
			this.UserDraggingThisNode.length > 0 &&
			this.UserDraggingThisNode != this.Socket?.id
		)
		{
			this.MakeReadOnly(true);
		} else
		{
			this.MakeReadOnly(false);
		}

		this.IsRoot = arg.IsRoot;

		if (this.UserDraggingThisNode != this.Socket?.id)
		{
			this.Position = JSONToVector(arg.Position);
		}

		// this.RemoveAllPremises(false);

		let reorderPremises = false;

		arg.Premises.forEach((element) =>
		{
			// Do we already have a premise with that ID?
			let existingPremise = this.Premises.find(
				(x) => x.NetworkID == element.ID
			);

			let premiseToUpdate: Premise | null = null;

			if (existingPremise)
			{
				premiseToUpdate = existingPremise;

				// Does this array index match the server element's array index?
				let existingPremiseArrayIndex = this.Premises.indexOf(existingPremise);
				let serverPremiseArrayIndex = arg.Premises.indexOf(element);

				// Found a difference so we should reorder afterwards
				if (existingPremiseArrayIndex != serverPremiseArrayIndex)
				{
					console.log("Detected premise out of order, will reorder");
					reorderPremises = true;
				}
			} else
			{
				let newPremise = this.AddPremise(element.TextField.Text, false);

				if (newPremise)
				{
					newPremise.NetworkID = element.ID;
					premiseToUpdate = newPremise;
				}
			}

			if (premiseToUpdate)
			{
				premiseToUpdate.UpdateFromJSON(element);
			}
		});

		for (let i = this.Premises.length - 1; i >= 0; i--)
		{
			let current = this.Premises[i];

			let matchingPremise = arg.Premises.find((x) => x.ID == current.NetworkID);

			// If we have a premise that's not on the server version, remove it:
			if (matchingPremise == null)
			{
				this.RemovePremise(current, false);
			}
		}

		// We detected premises out of order earlier, let's reorder them now:
		if (reorderPremises)
		{
			let newArray = this.PrepareReorderPremises();

			for (let i = 0; i < newArray.length; i++)
			{
				let elem = newArray[i];
				let match = arg.Premises.find((x) => x.ID == elem.Premise.NetworkID);

				if (match)
				{
					let index = arg.Premises.indexOf(match);
					elem.Weight = index;
				}
			}

			this.FinalizeReorderPremises(newArray);
		}

		this._conclusionTextField.UpdateFromJson(arg.Conclusion);

		if (arg.IsRoot)
		{
			this._conclusionTextField.MakeTextReadOnly(true);
		}

		this.RefreshNode();
	}

	public MakeArgumentJSON(): ArgumentJSON
	{
		let argJSON = new ArgumentJSON();

		this.Premises.forEach((x) =>
		{
			argJSON.Premises.push(new PremiseJSON(x.PremiseText));
		});

		argJSON.Conclusion = new TextFieldJSON(this.Conclusion);

		return argJSON;
	}

	public RemoveAllPremises(networkAction = true)
	{
		for (let i = this.Premises.length - 1; i >= 0; i--)
		{
			const element = this.Premises[i];
			this.RemovePremise(element, networkAction);
		}
	}

	public DeleteNode(networkAction = true): void
	{
		this.Premises.forEach((x) =>
		{
			if (x.HasSubGraph)
			{
				GraphManager.Instance.DeleteGraph(x.Subgraph, networkAction);
			}
		});

		super.DeleteNode(networkAction);
	}

	public RefreshNode()
	{
		for (let i = 0; i < this._premises.length; i++)
		{
			const element = this._premises[i];

			element.Label = "P" + (i + 1);
			element.Refresh();
		}

		this._conclusionTextField.Refresh();
		// ResizeTextArea.call(this._conclusionInputField);

		this._onRefreshed();
	}

	public MarkSuccessful(successful: boolean)
	{
		let green = GREEN_COLOR;
		let red = RED_COLOR;
		this._successDiv.style.backgroundColor = successful ? green : red;

		this._markedSuccessful = successful;
	}

	public OnDrag(event: MouseEvent | TouchEvent) { }

	protected CanDragFromClickedTarget(target: EventTarget)
	{
		if (target == this._fancyPlusSign)
		{
			return false;
		}

		return super.CanDragFromClickedTarget(target);
	}

	public MakeReadOnly(readonly: boolean)
	{
		this._deleteButton.disabled = readonly;
		this._addPremiseButton.disabled = readonly;

		this.Premises.forEach((x) =>
		{
			x.MakeReadOnly(readonly);
		});

		// this.MakeConclusionTextReadOnly(readonly);
		this._conclusionTextField.MakeTextReadOnly(readonly);
		this._conclusionWireButton.disabled = readonly;
	}

	// public MakeConclusionTextReadOnly(readonly: boolean)
	// {
	// 	this._conclusionInputField.disabled = readonly;
	// }

	public Update(): void
	{
		super.Update();

		if (this._refresh)
		{
			this.RefreshNode();
			this._refresh = false;
		}

		for (let i = 0; i < this._premises.length; i++)
		{
			const element = this._premises[i];

			element.Update();
		}

		this._conclusionTextField.Update();
	}
}
