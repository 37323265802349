import { PremiseJSON } from "../shared/jsontypes";
import { Vector } from "vector2d";
import { Argument } from "./argument";
import { GREEN_COLOR, RED_COLOR } from "./constants";
import { ContextMenuManager } from "./contextmenumanager";
import { PopupManager } from "./popupmanager";
import { Syllograph } from "./syllograph";
import { GetElementPos, HandleTextAreaResize, ResizeTextArea } from "./utilities";
import { GraphManager } from "./graphmanager";
import { TextField } from "./textfield";
import { Socket } from "socket.io-client";



export class Premise
{


	public Div: HTMLDivElement;
	public Argument: Argument;

	public OnPremiseDragOperationFinished = new Function();
	public OnSubgraphClicked = new Function();
	public OnSubgraphRemoveClicked = new Function();
	public OnTextChanged = new Function();


	get Socket(): Socket | null
	{
		return this._socket;
	}
	set Socket(value: Socket | null)
	{
		this._socket = value;
		this._textField.Socket = value;
	}

	private _socket: Socket | null = null;


	public NetworkID = "";

	private _deleteButton: HTMLButtonElement;
	private _refutationButton: HTMLButtonElement;
	private _dragButton: HTMLButtonElement;

	private _lastTouchClientX: number = 0;
	private _lastTouchClientY: number = 0;
	private _lastMoveTouchEvent: TouchEvent | null = null;

	get HasSubGraph(): boolean
	{
		return this._subgraph != null;
	}

	get Subgraph(): Syllograph | null
	{
		return this._subgraph;
	}
	set Subgraph(value: Syllograph | null)
	{
		this._subgraph = value;

		if (value == null)
		{
			this.MarkSubgraphNone();
		}
	}

	private _subgraph: Syllograph | null = null;

	private _reorderingPremise = false;
	private _reorderProgress: number = 0;

	get WirePosition(): Vector
	{
		var pos = GetElementPos(this._wireButton);
		return new Vector(pos.x, pos.y);
	}

	public OnDeleteClicked: Function = new Function();
	public OnRefuteClicked: Function = new Function();
	public OnWireButtonClicked: Function = new Function();

	get Label(): string
	{
		return this._labelDiv.innerText;
	}
	set Label(value: string)
	{
		this._labelDiv.innerText = value;
	}

	get PremiseText(): string
	{
		return this._textField.Field.value;
	}
	set PremiseText(value: string)
	{
		this._textField.Field.value = value;
	}

	private _labelDiv: HTMLDivElement;
	private _wireButton: HTMLButtonElement;
	// private _textArea: HTMLTextAreaElement;
	private _subgraphButton: HTMLButtonElement;

	private _textField: TextField;


	constructor(text: string, argument: Argument)
	{
		this.Argument = argument;

		let premiseDiv = document.createElement("div");
		premiseDiv.className = "premise";

		let wireButton = document.createElement("button");
		wireButton.className = "wirebutton";
		wireButton.tabIndex = 1;
		premiseDiv.appendChild(wireButton);

		let refutationButton = document.createElement("button");
		refutationButton.className = "refutationbutton";
		refutationButton.tabIndex = 1;
		premiseDiv.appendChild(refutationButton);
		this._refutationButton = refutationButton;

		let dragButton = document.createElement("button");
		dragButton.className = "dragbutton";
		dragButton.tabIndex = 1;
		premiseDiv.appendChild(dragButton);
		this._dragButton = dragButton;


		dragButton.onmousedown = (ev) =>
		{
			HandleDragButtonClickStart(ev);
		};

		dragButton.ontouchstart = (ev) =>
		{
			HandleDragButtonClickStart(ev);

			this._lastTouchClientY = ev.touches[0].clientY;
		}

		let HandleDragButtonClickStart = (ev: MouseEvent | TouchEvent) =>
		{
			if ((ev instanceof MouseEvent &&
				ev.button == 0) ||
				(window.TouchEvent && ev instanceof TouchEvent &&
					ev.touches.length == 1))
			{
				this._reorderingPremise = true;
				this._reorderProgress = 0;
			}
		}



		document.addEventListener('mousemove', (ev) =>
		{
			HandleMouseMove(ev);
		});

		document.addEventListener('touchmove', (ev) =>
		{
			HandleMouseMove(ev);

			this._lastMoveTouchEvent = ev;
		});

		let HandleMouseMove = (ev: MouseEvent | TouchEvent) =>
		{
			if (this._reorderingPremise)
			{
				if (ev instanceof MouseEvent)
				{
					this._reorderProgress += ev.movementY;
				}
				else if (window.TouchEvent && ev instanceof TouchEvent)
				{
					this._reorderProgress += ev.touches[0].clientY - this._lastTouchClientY;

					this._lastTouchClientY = ev.touches[0].clientY;
				}
			}
		}


		document.addEventListener('mouseup', (ev) =>
		{
			HandleMouseUp(ev);
		});

		document.addEventListener('touchend', (ev) =>
		{
			HandleMouseUp(ev);
		});

		let HandleMouseUp = (ev: MouseEvent | TouchEvent) =>
		{
			if (this._reorderingPremise)
			{
				if (window.TouchEvent && ev instanceof TouchEvent &&
					this._lastMoveTouchEvent)
				{
					ev = this._lastMoveTouchEvent;
				}

				this.OnPremiseDragOperationFinished(ev);
				this._reorderingPremise = false;
				this.Div.style.translate = "0px 0px";
			}
		}



		let premiseLabel = document.createElement("div");
		premiseLabel.className = "premisenum";
		premiseLabel.innerText = "P1";
		premiseDiv.appendChild(premiseLabel);

		let textField = new TextField(true, text);
		this._textField = textField;
		// let textArea = document.createElement("textarea");

		textField.Field.addEventListener('input', (ev) =>
		{
			this.OnTextChanged(textField.Field.value);
		});

		// textArea.Field.addEventListener('focusin', (ev) =>
		// {
		// 	if (this.Argument.Socket)
		// 	{
		// 		this.Argument.Socket.emit("capture-premise-text", this.NetworkID);
		// 	}
		// });
		// textArea.addEventListener('focusout', (ev) =>
		// {
		// 	if (this.Argument.Socket)
		// 	{
		// 		this.Argument.Socket.emit("release-premise-text", this.NetworkID);
		// 	}
		// });
		premiseDiv.appendChild(textField.Field);


		let subgraphButton = document.createElement("button");
		subgraphButton.className = "subgraphButton";
		subgraphButton.tabIndex = 1;
		// subgraphButton.innerText = "->";
		premiseDiv.appendChild(subgraphButton);

		let deleteButton = document.createElement("button");
		deleteButton.className = "deletebutton";
		deleteButton.tabIndex = 1;

		// deleteButton.innerText = "X";
		premiseDiv.appendChild(deleteButton);
		this._deleteButton = deleteButton;

		deleteButton.onclick = (ev) => this.OnDeleteClicked();
		refutationButton.onclick = (ev) => this.OnRefuteClicked();
		subgraphButton.onclick = (ev) => this.OnSubgraphClicked();

		this.Div = premiseDiv;
		this._labelDiv = premiseLabel;
		this._wireButton = wireButton;
		// this._textArea = textField.Field as HTMLTextAreaElement;
		this._subgraphButton = subgraphButton;

		this._wireButton.onmousedown = (ev) =>
		{
			this.OnWireButtonClicked(this);
		}

		this._wireButton.ontouchstart = (ev) =>
		{
			this.OnWireButtonClicked(this);
		}

		premiseDiv.oncontextmenu = (ev) =>
		{
			// if (document.getSelection()
			ContextMenuManager.Instance.AddButton("Refute premise", () =>
			{
				this.OnRefuteClicked();
			});

			ContextMenuManager.Instance.AddButton("Delete premise", () =>
			{
				this.OnDeleteClicked();
			});

			if (this.HasSubGraph)
			{
				ContextMenuManager.Instance.AddButton("Remove subgraph", () =>
				{
					PopupManager.Instance.AddPopup("Delete supporting argument?", true, true);

					PopupManager.Instance.OnOK = () =>
					{
						this.OnSubgraphRemoveClicked();
					}
				});
			}
		}
	}


	public MarkSubgraphSuccessful(successful: boolean)
	{
		this._subgraphButton.style.backgroundColor = successful ? GREEN_COLOR : RED_COLOR;
	}

	private MarkSubgraphNone()
	{
		this._subgraphButton.style.backgroundColor = "inherit";
	}

	public MakeReadOnly(readonly: boolean)
	{
		this._wireButton.disabled = readonly;
		this._subgraphButton.disabled = readonly;
		this.MakeTextReadOnly(readonly);
		this._deleteButton.disabled = readonly;
		this._refutationButton.disabled = readonly;
		this._dragButton.disabled = readonly;
	}

	public MakeTextReadOnly(readonly: boolean)
	{
		this._textField.MakeTextReadOnly(readonly);
	}

	public MakeJSONPremise()
	{
		return new PremiseJSON(this.PremiseText);
	}

	public UpdateFromJSON(element: PremiseJSON)
	{
		this._textField.UpdateFromJson(element.TextField);

		if (element.Subgraph != null)
		{
			// Does it not exist?
			if (GraphManager.Instance.GetGraphFromID(element.Subgraph.GraphID) == null)
			{
				let parentGraph = GraphManager.Instance.GetGraphFromID(element.Subgraph.ParentGraphID);

				if (parentGraph)
				{
					let setActive = element.Subgraph.CreatorUser == this.Socket?.id;
					let subgraph = GraphManager.Instance.AddSubgraph(setActive, this, parentGraph, false);

					if (subgraph)
					{
						subgraph.Syllograph.ID = element.Subgraph.GraphID;
					}
				}
			}
		}
	}

	public Refresh()
	{
		this._textField.Refresh();
		// ResizeTextArea.call(this._textArea);

		if (this.Subgraph)
		{
			let rootArg = this.Subgraph.GetRootArgument();

			if (rootArg)
			{
				rootArg.Conclusion = this.PremiseText;
			}
		}
	}

	public Update()
	{
		if (this._reorderingPremise)
		{
			this.Div.style.translate = "0px " + this._reorderProgress + "px";
		}

		this._textField.Update();

		// if (this.Argument.Socket &&
		// 	this.PremiseText !=
		// 	this._lastPremiseText &&
		// 	new Date().getTime() >
		// 	this._lastTextNetworkUpdate + 1000)
		// {
		// 	this._lastPremiseText = this.PremiseText;
		// 	this._lastTextNetworkUpdate = new Date().getTime();

		// 	this.Argument.Socket.emit("premise-text-changed", this.NetworkID, this.PremiseText);
		// }
	}
}