import { Vector } from "vector2d";
import { Argument } from "./argument";
import { CanvasObject } from "./canvaselement";
import { Premise } from "./premise";
import $ = require("jquery");
import { ArgumentRefutation } from "./argumentrefutation";


export function GetMousePos(canvas: HTMLCanvasElement, evt: MouseEvent | TouchEvent)
{
	var rect = canvas.getBoundingClientRect();
	return {
		x: evt instanceof MouseEvent ? evt.clientX - rect.left : (evt as TouchEvent).touches[0].clientX - rect.left,
		y: evt instanceof MouseEvent ? evt.clientY - rect.top : (evt as TouchEvent).touches[0].clientY - rect.top
	};
}



export function AddInput(pos: Vector)
{
	let textarea = document.createElement('textarea');
	let canvasdiv = document.getElementById('canvasdiv') as HTMLDivElement;
	canvasdiv.appendChild(textarea);

	textarea.style.resize = "none";
	textarea.style.position = 'absolute';
	textarea.style.left = (pos.x) + 'px';
	textarea.style.right = (pos.x) + 'px';
	textarea.style.top = pos.y + 'px';
	textarea.style.bottom = pos.y + 'px';
	textarea.style.overflowWrap = "soft";
	textarea.rows = 1;
	textarea.cols = 20;

	textarea.addEventListener("input", () =>
	{
		textarea.style.height = "auto";
		textarea.style.height = (textarea.scrollHeight) + "px";
	}, false);

	textarea.onfocus = (ev) =>
	{
		textarea.setSelectionRange(0, textarea.textLength);
	}


	return textarea;
}


export function GetElementPos(ele: HTMLElement): Vector
{
	let x = 0;
	let y = 0;

	let stopElement = document.getElementById('canvasdiv');

	while (ele != null)
	{
		x += ele.offsetLeft;
		y += ele.offsetTop;
		if (ele.offsetParent == stopElement)
		{
			break;
		}
		ele = ele.offsetParent as HTMLElement;
	}
	return new Vector(x, y);
}

export function GetMousePosOnCanvas(canvas: HTMLCanvasElement, evt: MouseEvent | TouchEvent)
{
	var rect = canvas.getBoundingClientRect();

	if (evt instanceof MouseEvent)
	{
		return {
			x: evt.clientX - rect.left,
			y: evt.clientY - rect.top
		};
	}
	else if (evt instanceof TouchEvent)
	{
		if (evt.touches.length > 0)
		{
			return {
				x: evt.touches[0].clientX - rect.left,
				y: evt.touches[0].clientY - rect.top
			};
		}
	}
}


export function FindArgumentInParents(elem: HTMLElement, canvasObjects: CanvasObject[]): Argument | null
{
	let currentElem = elem;

	let arg = canvasObjects.find((x) => Object.getPrototypeOf(x) == Argument.prototype && (x as Argument).NodeDiv == currentElem);

	if (arg == null)
	{
		if (elem != null)
		{
			return FindArgumentInParents(elem.parentElement as HTMLElement, canvasObjects);
		}
		else
		{
			return null;
		}
	}

	return arg as Argument;
}

export function FindPremiseInParents(elem: HTMLElement, canvasObjects: CanvasObject[]): Premise | null
{
	let currentElem = elem;

	let argument = FindArgumentInParents(elem, canvasObjects);

	if (argument)
	{
		let premise = argument.Premises.find((x) => x.Div == elem);

		if (premise == null)
		{
			if (elem != null)
			{
				return FindPremiseInParents(elem.parentElement as HTMLElement, canvasObjects);
			}
			else
			{
				return null;
			}
		}
		else
		{
			return premise;
		}
	}

	return null;
}


export function HandleTextAreaResize(textArea: HTMLTextAreaElement | HTMLInputElement)
{
	// getComputedStyle(textArea, null).height.replace("px", "");



	$(textArea)
		.on("input", ResizeTextArea);
}

export function ResizeTextArea(this: HTMLTextAreaElement | HTMLInputElement)
{
	$(this).height(parseFloat($(this).css("min-height")) != 0 ? parseFloat($(this).css("min-height")) : parseFloat($(this).css("font-size")));

	let outerHeight = $(this).outerHeight();

	//  the following will help the text expand as typing takes place
	while (outerHeight && outerHeight < this.scrollHeight)
	{
		outerHeight = $(this).outerHeight();

		let height = $(this).height();

		if (height)
		{
			$(this).height(height + 1);
		}
	};
}

export function GetAllArgumentParents(arg: Argument, refutations: ArgumentRefutation[]): Argument[]
{
	let args: Argument[] = [];

	let currentArg: Argument | null = arg;

	while (currentArg != null)
	{
		let refutation = refutations.find((x) => x.Refuting == currentArg);

		if (refutation)
		{
			let argParent = refutation.Refuted.Argument;
			args.push(argParent);
			currentArg = argParent;
		}
		else
		{
			currentArg = null;
		}
	}

	return args;
}

export function GetAllArgumentDescendants(arg: Argument, refutations: ArgumentRefutation[]): Argument[]
{
	let args: Argument[] = [];

	for (let i = 0; i < arg.Premises.length; i++)
	{
		const premise = arg.Premises[i];

		let relevantRefutations = refutations.filter((x) => x.Refuted == premise);

		for (let y = 0; y < relevantRefutations.length; y++)
		{
			const refutation = relevantRefutations[y];

			let childArg = refutation.Refuting;
			args.push(childArg);

			let theirDescendants = GetAllArgumentDescendants(childArg, refutations);
			args = args.concat(theirDescendants);
		}
	}


	return args;
}


