
import { io } from "socket.io-client";
import { AppState, IS_LIVE, SERVER_PORT, SERVER_URL } from "../shared/appstate";
import { Vector } from "vector2d";
import { ContextMenuManager } from "./contextmenumanager";
import { DragManager } from "./dragmanager";
import { GraphManager } from "./graphmanager";
import { PopupManager } from "./popupmanager";
import { Argument } from "./argument";
import { Syllograph } from "./syllograph";
import { VectorToJSON } from "../shared/sharedutils";
import { Socket } from "socket.io";
import { GetMousePosOnCanvas } from "./utilities";
import $ = require("jquery");


// const socket = io("ws://localhost:3000");//" + SERVER_URL + ":" + SERVER_PORT);

let url = "https://" + SERVER_URL;

if (IS_LIVE == false)
{
	url = "ws://localhost";
}

const socket = io(url + ":" + SERVER_PORT,
	{
		withCredentials: true
	});


const canvas = document.getElementById("canvas") as HTMLCanvasElement;
const context = canvas.getContext("2d") as CanvasRenderingContext2D;

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

const addArgumentButton = document.getElementById("addArgumentButton") as HTMLButtonElement;
const addParallelButton = document.getElementById("addParallelButton") as HTMLButtonElement;

let graphManager = new GraphManager(canvas);
graphManager.Socket = socket;
// graphManager.AddSyllograph(true);

let contextMenu = new ContextMenuManager();

let popupManager = new PopupManager();
let dragManager = new DragManager();

let connectingDiv = document.getElementById("reconnect-div");

// let syllographDiv = document.getElementById("syllograph-div");

// if (syllographDiv)
// {
// 	syllographDiv.style.display = "none";
// }



socket.on("connect", () =>
{
	if (connectingDiv)
	{
		connectingDiv.style.display = "none";
	}


	let roomID: string | null = "";
	let urlParams = new URLSearchParams(document.location.search);

	ClientDebug("Client joins. Room: " + roomID ? roomID : "none");

	if (urlParams.has('id'))
	{
		roomID = urlParams.get('id');
	}

	socket.emit("register-with-server", roomID);
});

socket.on("disconnect", (reason) =>
{
	if (connectingDiv)
	{
		connectingDiv.style.display = "flex";
	}
});

$(window).on('beforeunload', function ()
{
	socket.close();
});

socket.on("serverdebugmsg", (msg) =>
{
	console.log(msg);
});

socket.on("appstate", (appstate: string) =>
{
	// ClientDebug("Receiving appstate");
	let jsonAppstate: AppState = JSON.parse(appstate);
	HandleAppStateChanged(jsonAppstate, socket);
});


function HandleAppStateChanged(appstate: AppState, socket: any)
{
	console.log("Client: AppState changed. Num graphs: " + appstate.Graphs.length);
	appstate.Graphs.forEach((x) =>
	{
		let graph = GraphManager.Instance.GetGraphFromID(x.ID);

		if (graph == null)
		{
			// If it doesn't exist, we make it exist:
			let makeRootGraph = graphManager.ActiveSyllograph == null;

			if (makeRootGraph)
			{
				graph = graphManager.AddSyllograph(true, socket);
			}
		}

		if (graph)
		{
			ClientDebug("Updating graph: " + x.ID);
			graph.SetFromJSON(x);
		}

	});

	for (let i = graphManager.Syllographs.length - 1; i >= 0; i--)
	{
		let x = graphManager.Syllographs[i];
		let matchingGraph = appstate.Graphs.find((y) => y.ID == x.ID);

		// We have a graph x that doesn't exist on server:
		if (matchingGraph == null)
		{
			graphManager.DeleteGraph(x, false);
		}
	}
}


function ClientDebug(text: string)
{
	socket.emit("clientdebugmsg", "Client " + socket.id + ": " + text);
}

document.oncontextmenu = (ev) =>
{
	let realPos = GetMousePosOnCanvas(canvas, ev);

	if (realPos != undefined)
	{
		contextMenu.OpenContextMenu(new Vector(realPos.x, realPos.y));
		return false;
	}
}


window.onresize = (ev: UIEvent) =>
{
	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight;
};


addArgumentButton.onclick = (ev) => 
{
	if (socket.connected)
	{
		if (graphManager.ActiveSyllograph)
		{
			graphManager.ActiveSyllograph.Server_AddNewArgument(socket);
		}
	}
	else
	{
		graphManager.ActiveSyllograph?.AddNewArgument();

	}

}

addParallelButton.onclick = (ev) =>
{
	if (graphManager.ActiveSyllograph)
	{
		let pos = graphManager.ActiveSyllograph.CameraPosition;
		graphManager.ActiveSyllograph.AddNewParallel(true, pos);
	}
}

// A default argument when opening the app:
// graphManager.ActiveSyllograph?.AddNewArgument();



function ClearCanvas()
{
	context.clearRect(0, 0, canvas.width, canvas.height);
	// context.fillStyle = "rgba(0,0,0,1)";// #333333";
	// context.fillRect(0, 0, canvas.width, canvas.height);
}


let UpdateLoop = () =>
{
	ClearCanvas();

	graphManager.ActiveSyllograph?.Draw(context);


	requestAnimationFrame(UpdateLoop);
}

UpdateLoop();
