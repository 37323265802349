import { CanvasNode } from "./canvasnode";


export class DragManager
{
    public static Instance: DragManager;

	// public OnNodeDropped = new Function();
	
	// public OnNodeDropped = new CustomEvent('NodeDropped', { detail {name: 'dog'} });

    get DraggedObject(): CanvasNode | null
    {
        return this._currentDraggedObj;
    }

    private _currentDraggedObj: CanvasNode | null = null;

    constructor()
    {
        DragManager.Instance = this;

        // document.addEventListener('mouseup', (ev) =>
        // {
        //     if (this._currentDraggedObj)
		// 	{
				
        //     }

        // });
    }


    public RegisterDraggedObject(obj: CanvasNode)
    {
		this._currentDraggedObj = obj;
		

		obj.NodeDiv.addEventListener('CanvasNodeDragEnd', ((ev: CustomEvent) =>
		{
			let event = new CustomEvent('DragManager.OnNodeDropped', { detail: { node: this._currentDraggedObj } });
			dispatchEvent(event);
			// this.OnNodeDropped(this._currentDraggedObj);
			this._currentDraggedObj = null;
			
		}) as EventListener);
    }
}