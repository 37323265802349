import { ParallelJSON } from "../shared/jsontypes";
import { Vector } from "vector2d";
import { Argument } from "./argument";
import { CanvasNode } from "./canvasnode";
import { DragManager } from "./dragmanager";
import { GraphManager } from "./graphmanager";
import { FindArgumentInParents, GetMousePos, GetMousePosOnCanvas } from "./utilities";
import { JSONToVector, VectorJSON, VectorToJSON } from "../shared/sharedutils";
import { TextField } from "./textfield";
import { Socket } from "socket.io-client";



export class Parallel extends CanvasNode
{

	private _setupDiv: HTMLDivElement;
	private _dropZone: HTMLDivElement;
	private _hoveringArgument: Argument | null = null;
	private _argument: | Argument | null = null;

	private _argumentsDiv: HTMLDivElement;

	// get Socket(): Socket | null
	// {
	// 	return super.Socket;
	// }
	// set Socket(value: Socket | null)
	// {
	// 	super.Socket = value;

	// 	this._searchFields.forEach((x) =>
	// 	{
	// 		x.InputField.Socket = value;
	// 	});

	// 	this._replaceFields.forEach((x) =>
	// 	{
	// 		x.InputField.Socket = value;
	// 	});
	// }


	private _searchFields: { Div: HTMLDivElement, InputField: TextField, NetworkID: string }[] = [];
	private _replaceFields: { Div: HTMLDivElement, InputField: TextField, Argument: Argument | null, DeleteButton: HTMLButtonElement, NetworkID: string }[] = [];
	private _searchFieldsDiv: HTMLDivElement;
	private _replaceFieldsDiv: HTMLDivElement;

	constructor(pos: Vector, canvas: HTMLCanvasElement)
	{
		super(pos, canvas);

		this.NodeDiv.className += " containernode";
		this.NodeTitle = "PARALLEL";

		let setupDiv = document.createElement('div');
		this.ContentDiv.appendChild(setupDiv);
		this._setupDiv = setupDiv;

		let searchFieldsDiv = document.createElement('div');
		this._setupDiv.appendChild(searchFieldsDiv);
		this._searchFieldsDiv = searchFieldsDiv;

		let addSearchFieldButton = document.createElement('button');
		addSearchFieldButton.className = "syllobutton";
		addSearchFieldButton.innerText = "Add search";
		this._setupDiv.appendChild(addSearchFieldButton);

		addSearchFieldButton.onclick = (ev) =>
		{
			// if (this._argument)
			{
				this.AddSearchField();
			}
		}

		let replaceFieldsDiv = document.createElement('div');
		this._replaceFieldsDiv = replaceFieldsDiv;
		this._setupDiv.appendChild(replaceFieldsDiv);

		let replaceFieldButton = document.createElement('button');
		replaceFieldButton.className = "syllobutton";
		replaceFieldButton.innerText = "Add parallel";

		this._setupDiv.appendChild(replaceFieldButton);

		replaceFieldButton.onclick = (ev) =>
		{
			// if (this._argument)
			{
				this.AddReplaceField(this._argument);
			}
		}



		let dropZone = document.createElement("div");
		dropZone.className = "dropzone";
		dropZone.innerText = "Drop argument here".toUpperCase();
		// dropZone.style.position = "absolute";



		this._dropZone = dropZone;

		this.ContentDiv.appendChild(dropZone);

		let argumentsDiv = document.createElement('div');
		argumentsDiv.style.position = "absolute";
		this.ContentDiv.appendChild(argumentsDiv);

		this._argumentsDiv = argumentsDiv;

		dropZone.onmouseover = (ev) =>
		{
			if (DragManager.Instance.DraggedObject != null &&
				this._argument == null)
			{
				if (DragManager.Instance.DraggedObject instanceof Argument)
				{
					this._hoveringArgument = DragManager.Instance.DraggedObject;
					dropZone.className = "dropzone hoveredDropzone";
				}
			}
		};

		dropZone.onmouseleave = (ev) =>
		{
			dropZone.className = "dropzone";
			this._hoveringArgument = null;
		};


		addEventListener('DragManager.OnNodeDropped', ((event: CustomEvent) =>
		{
			let node = event.detail.node as CanvasNode;

			if (this._hoveringArgument &&
				this._hoveringArgument == node)
			{
				this.SetArgument(node as Argument);

			}
		}) as EventListener);


		// this.AddSearchField();
		// this.AddReplaceField(null);
	}


	public SetArgument(arg: Argument, networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("parallel-set-argument", this.NetworkID, arg.NetworkID);
			return;
		}

		if (this._argument)
		{
			return;
		}


		this._replaceFields.forEach((x) =>
		{
			x.Argument = this.AddParallelArgument(arg);
		});

		let dropZone = this._dropZone;
		dropZone.appendChild(arg.NodeDiv);

		arg.NodeDiv.className += " droppednode";
		arg.PositionDirect = new Vector(0, 0);
		arg.IgnoreScrollDelta = true;

		dropZone.style.position = "relative";
		dropZone.style.top = "0px";

		let matchingGraph = GraphManager.Instance.GetMatchingSyllographForParallel(this);

		if (matchingGraph)
		{
			matchingGraph.OnRemovedArgument = (removedArg: Argument) =>
			{
				if (removedArg == arg)
				{
					this.RemoveArgument(false);
				}
			};
		}

		let onDragBegin = (ev: CustomEvent) =>
		{
			let dragBeginArg = ev.detail.node as CanvasNode;

			if (dragBeginArg == this._argument)
			{
				let matchingGraph = GraphManager.Instance.GetMatchingSyllographForParallel(this);

				if (matchingGraph)
				{
					this.ParentArgumentToCanvas(dragBeginArg as Argument);
					let realPos = GetMousePosOnCanvas(this.Canvas, ev.detail.e);

					if (realPos != undefined)
					{
						let pos = new Vector(realPos.x, realPos.y).add(matchingGraph.Camera.Position);
						this.RemoveArgument(true, pos);
					}

					// dragBeginArg.Position = new Vector(realPos.x, realPos.y);

				}

				this.NodeDiv.removeEventListener('CanvasNodeDragBegin', onDragBegin as EventListener);
				// arg.NodeDiv.addEventListener('CanvasNodeDragEnd', this.OnCanvasNodeDragEnd as EventListener);

			}
		};

		arg.NodeDiv.addEventListener('CanvasNodeDragBegin', onDragBegin as EventListener);

		arg.Premises.forEach((x) =>
		{
			x.OnTextChanged = (input: string) =>
			{
				this.UpdateParallels(arg);
			}
		});

		arg.OnConclusionTextChanged = (input: string) =>
		{
			this.UpdateParallels(arg);
		};

		this._argument = arg;


		this.UpdateParallels(arg);
	}

	private ParentArgumentToCanvas(arg: Argument)
	{
		if (arg)
		{
			let canvasDiv = document.getElementById("canvasdiv");
			canvasDiv?.appendChild(arg.NodeDiv);
		}
	}

	public RemoveArgument(networkAction = true, position = new Vector(0, 0))
	{
		if (this.Socket && networkAction)
		{
			let pos = VectorToJSON(position);
			this.Socket.emit("parallel-clear-argument", this.NetworkID, pos);
			return;
		}

		let arg = this._argument;

		if (arg == null)
		{
			return;
		}

		arg.NodeDiv.className = "canvasnode";
		arg.IgnoreScrollDelta = false;


		this._replaceFields.forEach((x) =>
		{
			x.Argument?.DeleteNode(networkAction);
		});


		// this._dropZone.style.position = "absolute";
		// this._dropZone.style.top = "20px";

		this._argument = null;

		// this._dropZone.innerText = "DROP ARGUMENT HERE";

	}


	public AddSearchField(networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("parallel-add-search", this.NetworkID);
			return null;
		}

		let searchDiv = document.createElement('div');
		let searchTextSpan = document.createElement('span');
		searchTextSpan.textContent = "Search term";
		searchTextSpan.style.color = "white";
		searchTextSpan.style.userSelect = "none";
		searchDiv.style.margin = "5px";
		// searchDiv.style.float = "right";
		searchDiv.appendChild(searchTextSpan);

		let searchInputField = new TextField(false, "");
		searchInputField.Socket = this.Socket;
		// let searchInputField = document.createElement('input');
		// searchInputField.style.display = "inline";
		// searchInputField.style.position = "absolute";
		// searchInputField.style.right = "30px";
		searchDiv.appendChild(searchInputField.Field);

		let deleteButton = document.createElement('button');
		deleteButton.className = "deletebutton";
		// deleteButton.style.position = "absolute";
		// deleteButton.style.right = "0px";
		deleteButton.style.display = "inline";

		searchDiv.appendChild(deleteButton);
		this._searchFieldsDiv.appendChild(searchDiv);


		deleteButton.onclick = (ev) =>
		{
			console.log("Delete search");
			let searchField = this._searchFields.find((x) => x.InputField == searchInputField);
			if (searchField)
			{
				this.RemoveSearchField(searchField);
			}
		};

		searchInputField.Field.oninput = (ev) =>
		{
			if (this._argument)
			{
				this.UpdateParallels(this._argument);
			}
		};

		let newField = { Div: searchDiv, InputField: searchInputField, NetworkID: "" };
		this._searchFields.push(newField);

		return newField;
	}

	private RemoveSearchField(searchField: { Div: HTMLDivElement; InputField: TextField; NetworkID: string; }, networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("parallel-remove-search", this.NetworkID, searchField.NetworkID);
			return;
		}

		let index = this._searchFields.indexOf(searchField);
		searchField.Div.remove();
		this._searchFields.splice(index, 1);
	}

	public UpdateParallels(arg: Argument | null)
	{
		this._replaceFields.forEach((replaceField) =>
		{
			if (replaceField.Argument)
			{
				if (arg)
				{
					replaceField.Argument.CopyFromArgument(arg);
					replaceField.Argument.MakeReadOnly(true);
				}

				replaceField.Argument.Premises.forEach((premise) =>
				{
					let index = replaceField.Argument?.Premises.indexOf(premise);

					if (index != undefined && arg)
					{
						premise.PremiseText = arg.Premises[index].PremiseText;
					}
				});

				replaceField.Argument.Conclusion = arg ? arg.Conclusion : "";

				this._searchFields.forEach((searchField) =>
				{
					const regEscape = (v: any) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

					replaceField.Argument?.Premises.forEach((replacementPremise) =>
					{
						let index = replaceField.Argument?.Premises.indexOf(replacementPremise);

						if (index != undefined && arg)
						{
							let originalPremise = arg.Premises[index];


							if (replaceField.InputField.Field.value &&
								searchField.InputField.Field.value &&
								originalPremise.PremiseText.toLowerCase().includes(searchField.InputField.Field.value.toLowerCase()))
							{

								replacementPremise.PremiseText = replacementPremise.PremiseText.split(new RegExp(regEscape(searchField.InputField.Field.value), "ig")).join(replaceField.InputField.Field.value);
							}
						}
					});

					if (replaceField.InputField.Field.value &&
						searchField.InputField.Field.value &&
						arg &&
						arg.Conclusion.toLowerCase().includes(searchField.InputField.Field.value.toLowerCase()))
					{
						if (replaceField.Argument)
						{
							replaceField.Argument.Conclusion = replaceField.Argument.Conclusion.split(new RegExp(regEscape(searchField.InputField.Field.value), "ig")).join(replaceField.InputField.Field.value);
						}
					}
				});

				replaceField.Argument.RefreshNode();
			}
		});
	}


	public AddReplaceField(arg: Argument | null, networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("parallel-add-replace", this.NetworkID);
			return null;
		}


		let replaceDiv = document.createElement('div');
		let replaceTextSpan = document.createElement('span');
		replaceTextSpan.textContent = "Replacement term";
		replaceTextSpan.style.color = "white";
		replaceTextSpan.style.userSelect = "none";

		replaceDiv.appendChild(replaceTextSpan);

		let replacementInputField = new TextField(false, "");
		replacementInputField.Socket = this.Socket;
		// let replacementInputField = document.createElement('input');
		// replacementInputField.style.position = "absolute";
		// replacementInputField.style.right = "30px";


		replaceDiv.appendChild(replacementInputField.Field);
		// replaceDiv.style.float = "right";
		replaceDiv.style.margin = "5px";


		let deleteButton = document.createElement('button');
		deleteButton.className = "deletebutton";
		// deleteButton.style.position = "absolute";
		deleteButton.style.right = "0px";
		deleteButton.style.display = "inline";
		replaceDiv.appendChild(deleteButton);
		this._replaceFieldsDiv.appendChild(replaceDiv);
		console.log("Add replacemememnt");

		let deleteEvent = (ev: MouseEvent | TouchEvent) =>
		{
			let replaceField = this._replaceFields.find((x) => x.DeleteButton == deleteButton);
			if (replaceField)
			{
				this.RemoveReplaceField(replaceField);
			}
			else
			{
				console.log("ReplaceField null. Num: " + this._replaceFields.length);
			}

			deleteButton.removeEventListener('click', deleteEvent);
		};

		deleteButton.addEventListener('click', deleteEvent);



		replacementInputField.Field.oninput = (ev) =>
		{
			// if (this._argument)
			{
				this.UpdateParallels(this._argument);
			}
		};


		let newArg = arg ? this.AddParallelArgument(arg) : null;

		let newField = { Div: replaceDiv, InputField: replacementInputField, Argument: newArg, DeleteButton: deleteButton, NetworkID: "" };

		this._replaceFields.push(newField);

		console.log("ADD REPLACE");

		this.UpdateParallels(this._argument);


		return newField;
	}


	// private OnCanvasNodeDragEnd = (event: CustomEvent) =>
	// {
	// 	let ev = event.detail.e as MouseEvent;
	// 	let dragEndArg = event.detail.node as CanvasNode;

	// 	if (dragEndArg != this._hoveringArgument)
	// 	{

	// 		let canvasDiv = document.getElementById("canvasdiv");

	// 		if ((ev.target as HTMLElement).className.includes("dropzone") == false &&
	// 			GraphManager.Instance.ActiveSyllograph)
	// 		{
	// 			// canvasDiv?.appendChild(dragEndArg.NodeDiv);
	// 			// dragEndArg.Position = new Vector(ev.x, ev.y).add(GraphManager.Instance.ActiveSyllograph.Camera.Position);	
	// 		}
	// 	}

	// 	dragEndArg.NodeDiv.removeEventListener('CanvasNodeDragEnd', this.OnCanvasNodeDragEnd as EventListener);
	// }


	private RemoveReplaceField(replaceField: { Div: HTMLDivElement; InputField: TextField; Argument: Argument | null; DeleteButton: HTMLButtonElement; NetworkID: string; }, networkAction = true)
	{
		if (this.Socket && networkAction)
		{
			this.Socket.emit("parallel-remove-replace", this.NetworkID, replaceField.NetworkID);
			return;
		}

		let index = this._replaceFields.indexOf(replaceField);

		replaceField.Argument?.DeleteNode(false);
		replaceField.Div.remove();

		this._replaceFields.splice(index, 1);
	}

	private AddParallelArgument(arg: Argument)
	{
		let newArg = new Argument(new Vector(0, 0), this.Canvas, () => { });

		newArg.CopyFromArgument(arg);
		newArg.Conclusion = arg.Conclusion;
		newArg.NodeDiv.style.position = "relative";
		newArg.MakeReadOnly(true);
		newArg.NodeTitle = "Parallel Argument";

		this._argumentsDiv.appendChild(newArg.NodeDiv);
		return newArg;
	}

	protected CanDragFromClickedTarget(target: EventTarget)
	{
		let matchingGraph = GraphManager.Instance.GetMatchingSyllographForParallel(this);

		if (matchingGraph)
		{
			if (FindArgumentInParents(target as HTMLElement, matchingGraph.CanvasObjects))
			{
				return false;
			}
		}

		return super.CanDragFromClickedTarget(target);
	}

	public DeleteNode(networkAction = true)
	{
		if (networkAction == false)
		{
			let argToRemove = this._argument;

			if (this._argument)
			{
				this.RemoveArgument(false, this.Position);
			}

			if (argToRemove)
			{
				this.ParentArgumentToCanvas(argToRemove);
				argToRemove.Position = this.Position;
			}
		}

		super.DeleteNode(networkAction);
	}

	public SetFromJSON(x: ParallelJSON)
	{
		this.Position = JSONToVector(x.Position);
		this.UserDraggingThisNode = x.UserDraggingThisNode;
		this.NetworkID = x.ID;

		x.Searches.forEach((search) =>
		{
			// Already exists?
			let existingField = this._searchFields.find((x) => x.NetworkID == search.ID);

			if (existingField == null)
			{
				console.log("Adding search: " + search.Text);
				let searchField = this.AddSearchField(false);

				if (searchField)
				{
					searchField.InputField.Field.value = search.Text.Text;
					searchField.NetworkID = search.ID;
					searchField.InputField.UpdateFromJson(search.Text);

				}
			}
			else
			{
				existingField.InputField.UpdateFromJson(search.Text);
				this.UpdateParallels(this._argument);
			}
		});

		x.Replaces.forEach((replace) =>
		{
			let existingField = this._replaceFields.find((x) => x.NetworkID == replace.ID);

			if (existingField == null)
			{
				console.log("Adding replace: " + replace.Text);

				let replaceField = this.AddReplaceField(this._argument, false);

				if (replaceField)
				{
					replaceField.InputField.Field.value = replace.Text.Text;
					replaceField.NetworkID = replace.ID;
					replaceField.InputField.UpdateFromJson(replace.Text);

				}
			}
			else
			{
				existingField.InputField.UpdateFromJson(replace.Text);
				this.UpdateParallels(this._argument);
			}
		});


		for (let i = this._searchFields.length - 1; i >= 0; i--)
		{
			let field = this._searchFields[i];
			let existingField = x.Searches.find((y) => y.ID == field.NetworkID);

			if (existingField == null)
			{
				this.RemoveSearchField(field, false);
			}
		}

		for (let i = this._replaceFields.length - 1; i >= 0; i--)
		{
			let field = this._replaceFields[i];
			let existingField = x.Replaces.find((y) => y.ID == field.NetworkID);

			if (existingField == null)
			{
				this.RemoveReplaceField(field, false);
			}
		}

		if (x.ArgumentID != "" &&
			this._argument == null)
		{
			let matchingGraph = GraphManager.Instance.Syllographs.find((y) => y.CanvasObjects.includes(this));

			if (matchingGraph)
			{
				let matchingArg = matchingGraph.GetArgumentWithID(x.ArgumentID);

				if (matchingArg)
				{
					this.SetArgument(matchingArg, false);
				}
			}
		}

		if (this._argument != null &&
			x.ArgumentID == "")
		{
			let arg = this._argument;
			this.RemoveArgument(false);

			if (arg)
			{
				this.ParentArgumentToCanvas(arg);
			}
		}
	}

	public Update()
	{
		super.Update();

		this._searchFields.forEach((x) =>
		{
			x.InputField.Update();
		});

		this._replaceFields.forEach((x) =>
		{
			x.InputField.Update();
		});
	}
}