import { Vector } from "vector2d";
import { CanvasObject } from "./canvaselement";
import { CanvasGrid } from "./canvasgrid";
import { CanvasNode } from "./canvasnode";


export class Camera
{
	
	
	public Position = new Vector(0.0, 0.0);
	private _lastPosition = new Vector(0.0, 0.0);
    public CanvasObjects: CanvasObject[];
    public CanvasGrid: CanvasGrid;

    constructor(canvasObjects: CanvasObject[], canvasGrid: CanvasGrid)
    {
        this.CanvasObjects = canvasObjects;
        this.CanvasGrid = canvasGrid;
    }

	
	public Move(delta: Vector)
	{
		this.Position.add(delta);
		
		this.CanvasObjects.forEach((x) =>
		{
			x.HandleOffsetByGraph(new Vector(delta.x, delta.y));
		});
	}


    public Update(context: CanvasRenderingContext2D)
	{
        this.CanvasObjects.forEach((x) =>
		{
			let pos = new Vector(x.Position.x, x.Position.y);
			
			let ignore = false;
			if (x instanceof CanvasNode)
			{
				if ((x as CanvasNode).IgnoreScrollDelta)
				{
					ignore = true;
				}
			}
			
			if (ignore == false)
			{
				x.PositionDirect = pos.subtract(this.Position);
			}
        });



        let inversePos = new Vector(this.Position.x, this.Position.y).multiplyByScalar(-1);
        this.CanvasGrid.SetOffset(inversePos);
		this.CanvasGrid.Draw(context);

		this._lastPosition = new Vector(this.Position.x, this.Position.y);
	}
}