import { Vector } from "vector2d";

export class CanvasObject
{


	public Position: Vector;
	public Canvas: HTMLCanvasElement;
	public NetworkID = "";

    get PositionDirect(): Vector
	{
		return this.Position;
	}
	set PositionDirect(value: Vector)
	{
		
	}
	
	

	constructor(position: Vector, canvas: HTMLCanvasElement)
	{
		this.Position = new Vector(position.x, position.y);
		this.Canvas = canvas;
		
	}
	
	public HandleOffsetByGraph(delta: Vector) { }
	
	public Update() { }
	public Draw(context: CanvasRenderingContext2D) { }
	public Hide() { }
	public Show() { }
}