import { Vector } from "vector2d";



export class CanvasGrid
{
	private _canvas: HTMLCanvasElement;
	private _gridSpacing: number = 15;
	private _gridOffset = new Vector(0, 0);
	
	public LineOpacity = 0.15;
	
	constructor(canvas: HTMLCanvasElement, gridSpacing: number)
	{
		this._canvas = canvas;
		this._gridSpacing = gridSpacing;
	}
	
	
	public Draw(context: CanvasRenderingContext2D)
	{
		this.DrawGrid(context, this._gridSpacing);
		this.DrawGrid(context, this._gridSpacing * 4);
	}
	
	private DrawGrid(context: CanvasRenderingContext2D, gridSpacing: number)
	{
		let canvas = this._canvas;
		let gridOffset = this._gridOffset;

		context.strokeStyle = "rgba(0,0,0," + this.LineOpacity + ")";
		context.lineWidth = 2;

		for (let x = 0; x < canvas.width; x += gridSpacing)
		{
			let xpos = x + (gridOffset.x % gridSpacing);
			
			context.beginPath();
			context.moveTo(xpos, 0);
			context.lineTo(xpos, canvas.height);
			context.stroke();
		}

		for (let y = 0; y < canvas.height; y += gridSpacing)
		{
			let ypos = y + (gridOffset.y % gridSpacing);

			context.beginPath();
			context.moveTo(0, ypos);
			context.lineTo(canvas.width, ypos);
			context.stroke();
		}
	}
	
	public AddOffset(offset: Vector)
	{
		this._gridOffset.add(offset);
	}

	public SetOffset(offset: Vector)
	{
		this._gridOffset = offset;
	}
}