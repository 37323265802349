import { Guid } from "guid-typescript";
import { Vector } from "vector2d";
import { VectorJSON, VectorToJSON } from "./sharedutils";


export class NodeJSON
{
	public UserDraggingThisNode: string | null = null;
	public ID = Guid.EMPTY;
	public Position = VectorToJSON(new Vector(0, 0));

	
	constructor()
	{
		this.ID = Guid.create().toString();
	}
}

export class ArgumentJSON extends NodeJSON
{
	public Premises: PremiseJSON[] = [];
	public IsRoot = false;
	public Conclusion: TextFieldJSON;
	
	constructor()
	{
		super();
		this.Conclusion = new TextFieldJSON("");
	}
}

export class SubgraphJSON
{
	public ID;
	public GraphID;
	public ParentGraphID;
	public CreatorUser;
	
	constructor(graphID: string, parentGraphID: string, userThatCreatedThis: string)
	{
		this.ID = Guid.create().toString();
		this.GraphID = graphID;
		this.ParentGraphID = parentGraphID;
		this.CreatorUser = userThatCreatedThis;
	}
}

export class TextFieldJSON
{
	public Text: string;
	public ID: string;
	
	public UserCapturingText: string = "";
	public MostRecentCapturer = "";
	
	constructor(text: string)
	{
		this.ID = Guid.create().toString();
		this.Text = text;

	}
}

export class PremiseJSON
{
	public ID: string;
	public Subgraph: SubgraphJSON | null = null;
	public TextField: TextFieldJSON;
	
	constructor(text: string)
	{
		this.TextField = new TextFieldJSON(text);
		this.ID = Guid.create().toString();
	}
}

export class RefutationJSON
{
	public PremiseID: string;
	public ArgumentID: string;
	public ID: string;
	
	constructor(premiseID: string, argumentID: string)
	{
		this.PremiseID = premiseID;
		this.ArgumentID = argumentID;
		this.ID = Guid.create().toString();
	}
}

export class ParallelField
{
	public ID: string;
	public Text: TextFieldJSON;
	
	constructor(text: string)
	{
		this.ID = Guid.create().toString();
		this.Text = new TextFieldJSON(text);
	}
}

export class ParallelJSON extends NodeJSON
{
	public ArgumentID: string = "";
	public Searches: ParallelField[] = [];
	public Replaces: ParallelField[] = [];
	
	constructor()
	{
		super();
	}
}

export class SyllographJSON
{
	public Arguments: ArgumentJSON[] = [];
	public Parallels: ParallelJSON[] = [];
	public Refutations: RefutationJSON[] = [];
	public ID: string;

	constructor()
	{
		this.ID = Guid.create().toString();
	}
}