import { Socket } from "socket.io-client";
import { VectorToJSON } from "../shared/sharedutils";
import { Vector } from "vector2d";
import { CanvasObject } from "./canvaselement";
import { ContextMenuManager } from "./contextmenumanager";
import { DragManager } from "./dragmanager";
import { PopupButton, PopupManager } from "./popupmanager";
import { AddInput, GetElementPos, GetMousePos, GetMousePosOnCanvas } from "./utilities";



export class CanvasNode extends CanvasObject
{
	public TopBarDiv: HTMLDivElement;
	public ContentDiv: HTMLDivElement;
	public TitleDiv: HTMLDivElement;
	public IgnoreScrollDelta = false;

	get Socket(): Socket | null
	{
		return this._socket;
	}
	set Socket(value: Socket | null)
	{
		this._socket = value;
	}

	private _socket: Socket | null = null;

	public UserDraggingThisNode: string | null = null;

	private _tryDragMouseEvent: MouseEvent | TouchEvent | null = null;


	get PositionDirect(): Vector
	{
		let x = parseFloat(this.NodeDiv.style.left.replace("px", ""));
		let y = parseFloat(this.NodeDiv.style.top.replace("px", ""));
		return new Vector(x, y);
	}
	public set PositionDirect(value: Vector)
	{
		this._nodeDiv.style.left = value.x + "px";
		this._nodeDiv.style.top = value.y + "px";
	}

	// public Width: number = 100;
	get Width(): number
	{
		return this._nodeDiv.clientWidth;
	}
	get Height(): number
	{
		return this._nodeDiv.clientHeight;
	}

	get NodeDiv(): HTMLDivElement
	{
		return this._nodeDiv;
	}

	get NodeTitle(): string
	{
		return this.TitleDiv.innerText;
	}
	set NodeTitle(value: string)
	{
		this.TitleDiv.innerHTML = value.toUpperCase();
	}

	get ZIndex(): number
	{
		return parseInt(this.NodeDiv.style.zIndex);
	}
	set ZIndex(value: number)
	{
		this.NodeDiv.style.zIndex = value.toString();
	}

	public OnDeleteClicked = new Function();
	public OnMouseDown = new Function();
	// public OnDragBegin = new Function();
	// public OnDragEnd = new Function();

	public EnableDrag = true;

	protected _nodeDiv: HTMLDivElement;

	private _isClickedDown = false;
	private _isTryDragging = false;
	private _isDragging = false;

	private _clickedLocation = new Vector(0, 0);
	private _locationAtClick = new Vector(0, 0);

	protected _deleteButton: HTMLButtonElement;

	constructor(pos: Vector, canvas: HTMLCanvasElement)
	{
		super(pos, canvas);


		let canvasDiv = document.getElementById("canvasdiv");


		let nodeDiv = document.createElement("div") as HTMLDivElement;
		this._nodeDiv = nodeDiv;
		nodeDiv.className = "canvasnode";
		nodeDiv.style.top = pos.y + "px";
		nodeDiv.style.left = pos.x + "px";
		canvasDiv?.appendChild(nodeDiv);


		let topbarDiv = document.createElement("div");
		topbarDiv.className = "topbar";
		nodeDiv.appendChild(topbarDiv);
		this.TopBarDiv = topbarDiv;


		topbarDiv.oncontextmenu = (ev) =>
		{
			ContextMenuManager.Instance.AddButton("Rename", () =>
			{
				PopupManager.Instance.AddPopup("Enter new name", true, true, this.NodeTitle);

				PopupManager.Instance.OnOK = () =>
				{
					if (PopupManager.Instance.InputResult)
					{
						this.NodeTitle = PopupManager.Instance.InputResult.toUpperCase();
					}
				}
			});
		}

		let titleDiv = document.createElement('div');
		titleDiv.className = "nodetitle";
		this.TitleDiv = titleDiv;

		this.TopBarDiv.appendChild(titleDiv);


		let deleteButton = document.createElement("button");
		deleteButton.className = "deletebutton";
		deleteButton.innerText = "X";
		deleteButton.tabIndex = 1;
		topbarDiv.appendChild(deleteButton);
		deleteButton.onclick = (ev) => this.DeleteNode();

		this._deleteButton = deleteButton;

		this.ContentDiv = document.createElement('div');
		nodeDiv.appendChild(this.ContentDiv);

		this.ZIndex = 0;


		nodeDiv.onmousedown = (ev) =>
		{
			if (this.EnableDrag &&
				ev.button == 0 &&
				ev.target &&
				this.CanDragFromClickedTarget(ev.target))
			{
				HandleClickDown(ev);
			}
		}

		nodeDiv.ontouchstart = (ev) =>
		{
			if (this.EnableDrag &&
				ev.touches.length == 1 &&
				ev.target &&
				this.CanDragFromClickedTarget(ev.target))
			{
				HandleClickDown(ev);
			}
		}

		let HandleClickDown = (ev: MouseEvent | TouchEvent) =>
		{
			let realPos = GetMousePosOnCanvas(this.Canvas, ev);

			if (realPos != undefined)
			{
				this._clickedLocation = new Vector(realPos.x, realPos.y);
				this._locationAtClick = new Vector(this.Position.x, this.Position.y);

				this._isClickedDown = true;

				this.OnMouseDown();
			}
		}



		document.addEventListener('mouseup', (ev) =>
		{
			HandleClickEnd(ev);

		});

		document.addEventListener('touchend', (ev) =>
		{
			HandleClickEnd(ev);
		});

		let HandleClickEnd = (ev: MouseEvent | TouchEvent) =>
		{
			this._isClickedDown = false;

			if (this._isDragging)
			{
				if (this.Socket)
				{
					let newPos = VectorToJSON(this.Position);
					this.Socket.emit("client-end-drag", this.NetworkID, newPos);
				}

				this._isDragging = false;
				// this.OnDragEnd(ev);
				// let params = function { e: MouseEvent = ev };

				let dragEndEvent = new CustomEvent('CanvasNodeDragEnd', { detail: { e: ev, node: this } });
				this.NodeDiv.dispatchEvent(dragEndEvent);
			}

			this.NodeDiv.style.pointerEvents = "inherit";
		}

		document.addEventListener('mousemove', (ev) =>
		{
			HandleMouseMove(ev);
		});

		document.addEventListener('touchmove', (ev) =>
		{
			HandleMouseMove(ev);
		});

		let HandleMouseMove = (ev: MouseEvent | TouchEvent) =>
		{
			if (this._isClickedDown)
			{
				// console.log("this._isDragging: " + this._isDragging);
				if (this._isTryDragging == false &&
					this._isDragging == false)
				{
					this._isTryDragging = true;
					// console.log("Is try dragging");

					if (this.Socket)
					{
						// console.log("Client: Try begin drag");
						this._tryDragMouseEvent = ev;
						this.Socket.emit("client-try-begin-drag", this.NetworkID);
					}
					else
					{
						this.OnDragBegin(ev);
					}
				}


				if (this._isDragging == false &&
					this.UserDraggingThisNode == this.Socket?.id &&
					this._tryDragMouseEvent)
				{
					this.OnDragBegin(this._tryDragMouseEvent);
				}

				if (this._isDragging)
				{
					let diff = new Vector(this._clickedLocation.x, this._clickedLocation.y).subtract(this._locationAtClick);
					let mousePos = GetMousePos(canvas, ev);

					let dragLocation = mousePos;
					dragLocation.x -= diff.x;
					dragLocation.y -= diff.y;

					this.Position.x = dragLocation.x;
					this.Position.y = dragLocation.y;

					this.OnDrag(ev);
				}
			}
		}
	}


	private OnDragBegin(ev: MouseEvent | TouchEvent)
	{
		this._isTryDragging = false;
		this._isDragging = true;

		let dragBeginEvent = new CustomEvent('CanvasNodeDragBegin', { detail: { e: ev, node: this } });
		this.NodeDiv.dispatchEvent(dragBeginEvent);

		DragManager.Instance.RegisterDraggedObject(this);
		this.NodeDiv.style.pointerEvents = "none";
	}

	public DeleteNode(networkAction = true)
	{
		this.OnDeleteClicked(networkAction);

		if (networkAction == false)
		{
			this._nodeDiv.remove();
		}
	}

	public Update(): void
	{
		// this._nodeDiv.style.left = this.Position.x + "px";
		// this._nodeDiv.style.top = this.Position.y + "px";
	}


	public Draw(context: CanvasRenderingContext2D): void
	{
		// context.fillStyle = "gray";
		// context.fillRect(this.Position.x, this.Position.y, this.Width, this.Height);
	}

	public Show(): void
	{
		this.NodeDiv.style.display = "inherit";
	}

	public Hide(): void
	{
		this.NodeDiv.style.display = "none";
	}

	public OnDrag(event: MouseEvent | TouchEvent): void
	{

	}

	public EnableDeleteButton(show: boolean)
	{
		this._deleteButton.disabled = !show;
	}

	// public HandleOffsetByGraph(delta: Vector): void
	// {
	// 	super.HandleOffsetByGraph(delta);

	// 	if (this.IgnoreScrollDelta)
	// 	{
	// 		return;
	// 	}

	// 	this.Position.add(delta);
	// }

	protected CanDragFromClickedTarget(target: EventTarget)
	{
		let classType = Object.prototype.toString.call(target);

		if (classType.includes("HTMLButtonElement") ||
			classType.includes("TextAreaElement") ||
			classType.includes("InputElement"))
		{
			return false;
		}

		return true;
	}
}