import { Socket } from "socket.io-client";
import { Vector } from "vector2d/src/Vector";
import { Argument } from "./argument";
import { ContextMenuManager } from "./contextmenumanager";
import { Parallel } from "./parallel";
import { PopupManager } from "./popupmanager";
import { Premise } from "./premise";
import { Syllograph } from "./syllograph";


class Subgraph
{
	public Syllograph: Syllograph;
	public Premise: Premise;
	public ParentGraph: Syllograph;

	constructor(graph: Syllograph, premise: Premise, parentGraph: Syllograph)
	{
		this.Syllograph = graph;
		this.Premise = premise;
		this.ParentGraph = parentGraph;
	}
}

class GraphNavElement
{
	public Syllograph: Syllograph;
	public Button: HTMLButtonElement;

	constructor(syllograph: Syllograph, button: HTMLButtonElement)
	{
		this.Syllograph = syllograph;
		this.Button = button;
	}
}

export class GraphManager
{

	public static Instance: GraphManager;

	public Socket: Socket | null = null;

	get ActiveSyllograph(): Syllograph | null
	{
		return this._activeSyllograph;
	}
	set ActiveSyllograph(value: Syllograph | null)
	{
		if (this._activeSyllograph != null)
		{
			this.HideGraph(this._activeSyllograph);
		}


		if (value)
		{
			this._canvas.oncontextmenu = (ev) => value.OnContextMenu(ev);
			value.ShowAllCanvasNodes();
			value.GetAllArguments().forEach((x) => x.RefreshNode());
		}

		this._activeSyllograph = value;
	}

	get Syllographs(): Syllograph[]
	{
		return this._syllographs;
	}

	private _activeSyllograph: Syllograph | null = null;
	private _syllographs: Syllograph[] = [];
	private _subgraphs: Subgraph[] = [];

	private _canvas: HTMLCanvasElement;
	private _subgraphStack: GraphNavElement[] = [];

	private _deleteGraphButton: HTMLButtonElement;

	constructor(canvas: HTMLCanvasElement)
	{
		GraphManager.Instance = this;

		this.ActiveSyllograph = null;
		this._canvas = canvas;

		this._deleteGraphButton = document.getElementById("subgraph-delete") as HTMLButtonElement;

		this._deleteGraphButton.onclick = () =>
		{
			PopupManager.Instance.AddPopup("Delete supporting argument?", true, true);

			PopupManager.Instance.OnOK = () =>
			{
				let graphToDelete = this.ActiveSyllograph;
				this.DeleteGraph(graphToDelete);
			};
		}
	}

	public DeleteGraph(graphToDelete: Syllograph | null, networkAction = true)
	{
		if (this.Socket && networkAction &&
			graphToDelete)
		{
			this.Socket.emit("remove-graph", graphToDelete.ID);
			return;
		}

		let premiseThatHasTheSubgraph!: Premise;

		let subgraphElem = this._subgraphs.find((x) => x.Premise.Subgraph == graphToDelete);

		if (subgraphElem)
		{
			premiseThatHasTheSubgraph = subgraphElem.Premise;
		}

		let navElem = this._subgraphStack.find((x) => x.Syllograph == graphToDelete);

		let graphToNavigateTo!: GraphNavElement;

		if (navElem)
		{
			let indexInArrayOfOneToDelete = this._subgraphStack.indexOf(navElem);
			if (indexInArrayOfOneToDelete > 0)
			{
				graphToNavigateTo = this._subgraphStack[indexInArrayOfOneToDelete - 1];
			}
		}

		if (graphToNavigateTo)
		{
			this.NavigateTo(graphToNavigateTo);
		}

		this.DeleteGraphRecursive(graphToDelete);

		if (premiseThatHasTheSubgraph)
		{
			premiseThatHasTheSubgraph.Subgraph = null;

			premiseThatHasTheSubgraph.Argument.RefreshNode();

		}
	}

	public DeleteGraphRecursive(graphToDelete: Syllograph | null)
	{
		if (graphToDelete == null)
		{
			return;
		}

		let allArgs = graphToDelete.GetAllArguments();

		let graphsToDelete: Syllograph[] = [];

		if (allArgs)
		{
			for (let i = 0; i < allArgs.length; i++)
			{
				const argument = allArgs[i];

				for (let j = 0; j < argument.Premises.length; j++)
				{
					const premise = argument.Premises[j];

					if (premise.Subgraph)
					{
						graphsToDelete.push(premise.Subgraph);
						this.DeleteGraphRecursive(premise.Subgraph);
					}
				}
			}
		}

		graphsToDelete.push(graphToDelete);



		for (let i = 0; i < graphsToDelete.length; i++)
		{
			const element = graphsToDelete[i];

			let index = this._syllographs.indexOf(element);

			if (index >= 0)
			{
				this._syllographs.splice(index, 1);
			}

			let subgraphElem = this._subgraphs.find((x) => x.Syllograph == element);

			if (subgraphElem)
			{
				subgraphElem.Premise.Subgraph = null;

				let subgraphIndex = this._subgraphs.indexOf(subgraphElem);

				if (subgraphIndex >= 0)
				{
					this._subgraphs.splice(subgraphIndex);
				}
			}
		}
	}


	public AddSyllograph(setActive = false, socket: Socket | null = null)
	{
		let newGraph = new Syllograph(this._canvas, socket);

		if (newGraph &&
			this._syllographs.includes(newGraph) == false)
		{
			this._syllographs.push(newGraph);
		}

		newGraph.OnPremiseSubgraphClicked = (argument: Argument, premise: Premise) =>
		{
			if (premise.Subgraph)
			{
				this.ActiveSyllograph = premise.Subgraph;
				this.SetupNavigation(premise, premise.Subgraph);
			}
			else
			{
				this.AddSubgraph(setActive, premise, newGraph);
			}
		};

		newGraph.OnPremiseSubgraphRemoveClicked = (argument: Argument, premise: Premise) =>
		{
			if (premise.Subgraph)
			{
				this.DeleteGraph(premise.Subgraph);
			}
		};

		newGraph.OnPremiseRemove = (premise: Premise, networkAction: boolean) =>
		{
			if (premise.Subgraph)
			{
				this.DeleteGraph(premise.Subgraph, networkAction);
			}
		}

		newGraph.OnSuccessManagerUpdated = () =>
		{
			let updatedSubgraph = this._subgraphs.find((x) => x.Syllograph == newGraph);
			let argToUpdate = updatedSubgraph?.Premise.Argument;

			argToUpdate?.RefreshNode();
		};

		if (setActive)
		{
			this.ActiveSyllograph = newGraph;

			if (this._subgraphStack.length == 0)
			{
				this.SetupNavigation(null, newGraph);
			}
		}

		return newGraph;
	}

	public AddSubgraph(setActive = false, premise: Premise | null, parentGraph: Syllograph, networkAction = true)
	{
		if (this.Socket &&
			networkAction &&
			premise)
		{
			this.Socket.emit("add-subgraph", premise.NetworkID, parentGraph.ID);
			return;
		}

		let newGraph = this.AddSyllograph(setActive, this.Socket);

		if (setActive)
		{
			this.SetupNavigation(premise, newGraph);
		}

		if (premise)
		{
			let subgraph = new Subgraph(newGraph, premise, parentGraph);
			this._subgraphs.push(subgraph);
			premise.Subgraph = newGraph;

			// let rootArg = newGraph.AddNewArgument();
			// rootArg.NodeTitle = "Supporting argument";


			// rootArg.Conclusion = premise.PremiseText;
			// rootArg.IsRoot = true;

			return subgraph;
		}

		return null;
	}

	private SetupNavigation(premise: Premise | null, newGraph: Syllograph)
	{
		let navDiv = document.getElementById("subgraph-navigation") as HTMLDivElement;

		let subgraphDeleteButton = this._deleteGraphButton;

		if (subgraphDeleteButton)
		{
			subgraphDeleteButton.style.display = "none";
		}

		if (navDiv.innerText.length > 1)
		{
			let title = document.createElement('div');
			title.innerText = " > ";
			title.style.display = "inline";
			navDiv.appendChild(title);

			if (subgraphDeleteButton)
			{
				subgraphDeleteButton.style.display = "block";
			}

		}



		let navButton = document.createElement('button');

		navButton.textContent = premise ? premise.PremiseText : "Root";
		navButton.className = "nav-text";

		this._subgraphStack.push(new GraphNavElement(newGraph, navButton));

		navButton.onclick = (ev) =>
		{
			let navElem = this._subgraphStack.find((x) => x.Button == navButton);

			if (navElem)
			{
				this.NavigateTo(navElem);
			}
		};

		navDiv.appendChild(navButton);
	}

	public NavigateTo(navElem: GraphNavElement)
	{
		let navDiv = document.getElementById("subgraph-navigation") as HTMLDivElement;


		if (navElem)
		{
			this.ActiveSyllograph = navElem.Syllograph;

			let graphToGoTo: Syllograph | null | undefined = this._subgraphStack[this._subgraphStack.length - 1].Syllograph;

			while (graphToGoTo != navElem.Syllograph)
			{
				let lastElem = this._subgraphStack.find((x) => x.Syllograph == graphToGoTo);


				if (lastElem)
				{
					let next = this._subgraphStack.pop();

					if (next)
					{
						graphToGoTo = next?.Syllograph;
					}
					else
					{
						break;
					}
				}
				else
				{
					break;
				}
			}

			for (let i = navDiv.children.length - 1; i >= 0; i--)
			{
				let element = navDiv.children[i];

				if (element != navElem.Button)
				{
					navDiv.removeChild(element);
				}
				else
				{
					break;
				}
			}

			let subgraphDeleteButton = this._deleteGraphButton;

			if (navDiv.children.length <= 1 &&
				subgraphDeleteButton)
			{
				subgraphDeleteButton.style.display = "none";
			}
		}
	}

	public GetGraphFromID(id: string): Syllograph | undefined
	{
		return this._syllographs.find((x) => x.ID == id);
	}

	public GetMatchingSyllographForParallel(parallel: Parallel)
	{
		let matchingParallel = this._syllographs.find((x) => x.GetParallelWithID(parallel.NetworkID) != null);
		return matchingParallel;
	}

	public GetMatchingSyllographForArgument(argument: Argument)
	{
		let matchingArg = this._syllographs.find((x) => x.GetArgumentWithID(argument.NetworkID) != null);
		return matchingArg;
	}

	public ShowGraph(_graph: Syllograph)
	{
		_graph.ShowAllCanvasNodes();
	}

	public HideGraph(_graph: Syllograph)
	{
		_graph.HideAllCanvasNodes();
	}
}