import { Socket } from "socket.io-client";
import { TextFieldJSON } from "../shared/jsontypes";
import { HandleTextAreaResize, ResizeTextArea } from "./utilities";


export class TextField
{

	public Field: HTMLTextAreaElement | HTMLInputElement;
	
	public Socket: Socket | null = null;
	public NetworkID = "";
	public UserCapturingText = "";
	public MostRecentCapturer = "";
	
	private _lastFieldValue;
	private _lastNetworkUpdate = 0;
	
	constructor(textArea: boolean, text = "")
	{
		if (textArea)
		{
			this.Field = document.createElement('textarea');
		}
		else
		{
			this.Field = document.createElement('input');
		}
		
		HandleTextAreaResize(this.Field);
		this.Field.className = "nodetextarea";
		this.Field.value = text;
		this._lastFieldValue = text;
		
		this.Field.addEventListener('focusin', (ev) =>
		{
			if (this.Socket)
			{
				console.log("FOCUS IN: " + this.NetworkID);
				this.Socket.emit("capture-text-field", this.NetworkID);
			}
		});
		
		this.Field.addEventListener('focusout', (ev) =>
		{
			if (this.Socket)
			{
				this.Socket.emit("release-text-field", this.NetworkID);
			}
		});

	}
	
	public MakeTextReadOnly(readonly: boolean)
	{
		this.Field.disabled = readonly;
	}
	
	public Refresh()
	{
		ResizeTextArea.call(this.Field);
	}
	
	public UpdateFromJson(TextField: TextFieldJSON)
	{
		this.UserCapturingText = TextField.UserCapturingText;
		this.NetworkID = TextField.ID;

		if (TextField.UserCapturingText != "" &&
			TextField.UserCapturingText != this.Socket?.id)
		{
			this.MakeTextReadOnly(true);
		}
		else
		{
			this.MakeTextReadOnly(false);
		}
		
		if (TextField.MostRecentCapturer != this.Socket?.id)
		{
			this.Field.value = TextField.Text;
		}
	}
	
	public Update()
	{
		if (this.Socket &&
			this.Field.value !=
			this._lastFieldValue &&
			new Date().getTime() >
			this._lastNetworkUpdate + 300)
		{
			this._lastFieldValue = this.Field.value;
			this._lastNetworkUpdate = new Date().getTime();

			this.Socket.emit("text-field-changed", this.NetworkID, this.Field.value);
		}
	}
}