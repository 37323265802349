import { Vector } from "vector2d";
import { Argument } from "./argument";
import { GraphManager } from "./graphmanager";
import { Premise } from "./premise";
import { Wire } from "./wire";


export class ArgumentRefutation
{
	public RefutationWire: Wire;
	
	public Refuted: Premise;
	public Refuting: Argument;
	
	public NetworkID: string = "";
	
	constructor(refuted: Premise, refuting: Argument, canvas: HTMLCanvasElement)
	{
		let pos = new Vector(refuted.WirePosition.x, refuted.WirePosition.y);//.add(addPos);
		this.RefutationWire = new Wire(pos, 15, 1, canvas);
		this.RefutationWire.Iterations = 2;
		this.Refuted = refuted;
		this.Refuting = refuting;
	}
	
	
	public Draw(context: CanvasRenderingContext2D)
	{
		var wire = this.RefutationWire;
		wire.Position = this.Refuted.WirePosition;
		wire.MoveEnd(this.Refuting.ConclusionWirePosition);
		// wire.Update();
	}
}