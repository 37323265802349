import { Vector } from "vector2d";

export class VectorJSON
{
	public X: number;
	public Y: number;

	constructor(vec: Vector)
	{
		this.X = vec.x;
		this.Y = vec.y;
	}
}


export function VectorToJSON(vec: Vector)
{
	let json = new VectorJSON(vec);
	return JSON.stringify(json);
}

export function JSONToVector(vecString: string)
{
	let vec: VectorJSON = JSON.parse(vecString)

	return new Vector(vec.X, vec.Y);
}