import { Argument } from "./argument";
import { ArgumentRefutation } from "./argumentrefutation";


export class SuccessManager
{
	public OnUpdated: Function = new Function();



	constructor()
	{
		
	}
	
	
	
	public ProcessArgument(argument: Argument, refutations: ArgumentRefutation[])
	{
		var topArgument = this.FindTopArgumentInRefutationChain(argument, refutations);
		
		if (topArgument)
		{
			this.RecursiveProcessArgument(topArgument, refutations);
			this.OnUpdated();
		}
	}
	
	private RecursiveProcessArgument(argument: Argument, refutations: ArgumentRefutation[])
	{
		let premises = argument.Premises;
		let successfulArg = true;

		for (let i = 0; i < premises.length; i++)
		{
			const premise = premises[i];
			
			if (premise.HasSubGraph)
			{
				let rootArg = premise.Subgraph?.GetRootArgument();

				if (rootArg)
				{
					let successfulSubgraph = true;
					if (rootArg.MarkedSuccessful == false)
					{
						successfulArg = false;
						successfulSubgraph = false;
					}

					premise.MarkSubgraphSuccessful(successfulSubgraph);
				}
			}


			let premiseRefutations = refutations.filter((x) => x.Refuted == premise);

			for (let j = 0; j < premiseRefutations.length; j++)
			{
				const refutation = premiseRefutations[j];

				if (this.RecursiveProcessArgument(refutation.Refuting, refutations))
				{
					successfulArg = false;
				}
			}
		}

		argument.MarkSuccessful(successfulArg);

		return successfulArg;
	}
	
	private FindTopArgumentInRefutationChain(argument: Argument, refutations: ArgumentRefutation[], original: Argument | null = null) : Argument | null
	{
		let refutation = refutations.find((x) => x.Refuting == argument);
		
		if (argument == original)
		{
			console.log("Circular, aborting");
			return null;
		}
		
		if (original == null)
		{
			original = argument;
		}
		
		if (refutation)
		{
			let arg = this.FindTopArgumentInRefutationChain(refutation.Refuted.Argument, refutations, original);
			
			return arg;
		}
		
		return argument;
	}
}